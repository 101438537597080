@import '../styles/constants';

.posts-list {
  a {
    min-width: 155px;
  }

  h1 {
    font-size: 40px;
    text-align: center;
    padding-top: 100px;
    margin: 0;
    color: #a39da9;
  }

  .posts-wrapper {
    margin: 0 -15px;

    h4 {
      font-size: 1.28rem;
      margin: 40px 15px 20px 15px;
    }
  }

  @media (min-width: 993px) {
    .get-started-wrapper {
      width: 100%;
    }
    .left-button {
      //  width: 135px;
      float: left;
    }
    .right-button {
      margin-left: 190px;
    }
  }

  @include mobile {
    .posts-wrapper {
      margin: 0;
      padding: 0;
    }
  }

  .get-started-button {
    padding-right: 40px;
    padding-left: 40px;
  }

  .inactive-link {
    color: #3f3356;
    font-size: 15px;
    line-height: 20px;
    padding: 8px 16px;
    opacity: 0.6;
  }

  .get-started-menu {
    margin-left: 15px;
  }
}
