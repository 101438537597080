@import '../styles/constants';

header.header-wrapper {
  height: 100px;
  margin: 0 70px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  z-index: 1;
  position: relative;
  border-radius: 0 0 12px 12px;

  h1 {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.27px;
    line-height: 32px;
    padding: 36px 0 0 40px;
  }

  .selector-wrapper {
    height: 80px;
    align-self: center;
    border-left: 1px solid #dde1ee;
    padding: 19px 35px 19px 39px;
  }

  button {
    background: none;
    border: none;

    &.header-button {
      color: $main-contrast;
      font-size: 20px;
      letter-spacing: 0.8px;
      line-height: 26px;
    }

    &:focus {
      outline: none;
    }

    span {
      margin-right: 10px;
      margin-left: 20px;
      vertical-align: middle;
    }
  }

  .avatar {
    border-radius: 20px;
    width: 40px;
    height: 40px;
  }

  .arrowDown {
    vertical-align: middle;
  }

  .invisible {
    display: none;
  }

  @include mobile {
    margin: 0;
    height: 61px;
    padding: 10px 20px 10px 60px;
    border-radius: 0;
    border-bottom: 1px solid #dde1ee;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    h1 {
      font-size: 13px;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      line-height: 42px;
    }

    .selector-wrapper {
      height: auto;
      align-self: center;
      border-left: 0;
      padding: 0;

      .dropdown {
        font-size: 0;
        position: static !important;

        .dropdown-menu {
          border-radius: 0;
          margin-top: 61px;
          border: 0;
          transform: none !important;
          right: 0;
        }
      }

      .arrowDown {
        display: none;
      }

      .header-button {
        font-size: 14px;
        padding: 0;

        span {
          margin: 8px 10px 0 0;
          display: none;
        }
      }
    }
  }
}
