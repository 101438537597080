@import '../styles/constants';

.account-wrapper {
  min-height: 100%;
  background: #fbfbfb;

  h4 {
    margin-bottom: 32px;
  }

  .account-input {
    max-width: 500px;
    margin-bottom: 22px;
  }

  .account-select {
    max-width: 500px;
  }

  .instructions {
    color: #1a051d;
    font-size: 13px;
    letter-spacing: 0.14px;
    line-height: 17px;
    margin-bottom: 16px;
    max-width: 400px;
  }

  .billing-card {
    .float-left {
      padding-right: 30px;

      &:last-child {
        padding-right: 0;
      }
    }

    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .my-invoices {
      margin-top: 30px;
    }
  }

  .subscription-card {
    .btn {
      font-size: 13px;
    }

    .clearfix {
      margin-bottom: 12px;
    }

    .float-left {
      padding-right: 30px;
      font-size: 13px;
      vertical-align: top;

      &.nex-billing {
        padding-right: 0;
        line-height: 28px;
      }

      b {
        font-size: 17px;
        display: inline-block;
        padding-right: 5px;
      }

      span {
        font-size: 12px;
        display: inline-block;
      }
    }
  }

  @include mobile {
    h4 {
      margin-bottom: 20px;
    }

    .account-input,
    .account-select {
      margin-bottom: 15px;

      label {
        margin-bottom: 5px;
      }
    }

    .button-wrapper .btn {
      width: 100%;
    }
  }
}
