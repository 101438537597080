.settings-wrapper {
  background: #fbfbfb;

  h4 {
    margin-bottom: 10px;
  }

  .settings-select {
    max-width: 340px;
  }

  .separator {
    height: 1px;
    background: #e9e9e9;
    margin: 50px 0;
  }

  .subtitle {
    color: #b5b4b6;
    font-size: 12px;
    letter-spacing: 0.14px;
    line-height: 16px;
    margin: 16px 0 0 0;
  }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 340px;
    flex-wrap: wrap;
  }

  .input-wrapper .form-input-wrapper {
    margin-top: 16px;
  }

  .settings-input {
    width: 155px;
  }
}

@media (max-width: 992px) {
  .settings-wrapper, .input-wrapper {
    max-width: 100% !important;

    .settings-input {
      width: 45%;
    }

    .settings-select {
      max-width: 100%;
    }
    .btn {
      min-width: 100%;
    }

    .add-platform {
      flex-wrap: wrap;

      .settings-select, .button-wrapper {
        width: 100%;
        max-width: 100% !important;

        .btn {
          min-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .settings-wrapper, .input-wrapper {
    .btn {
      margin: 10px 0 0 0;
    }
  }
}

@media (max-width: 576px) {
  .settings-wrapper, .input-wrapper {
    .list-platform {
      overflow: auto;
    }

    table {
      tr {
        div.icon {
          display: none !important;
        }
      }
    }
  }
}