.plagiarism-modal {
  max-width: 600px;
  border-radius: 10px;
  border-radius: 10px;
  overflow: auto;
  max-height: 100%;
  .plagiarism-results {
    margin: 10px 0;
    .plagiarism-modal-title {
      font-size: 1rem;
      font-weight: bold;
    }
    .plagiarism-modal-title,
    .plagiarism-modal-subtext,
    .plagiarism-modal-link,
    .plagiarism-modal-matched-word,
    .plagiarism-modal-percentage-word {
      text-align: justify;
    }
    .plagiarism-modal-percentage-word {
      float: right;
    }
    .plagiarism-modal-subtext {
      margin: 10px 0;
    }
  }

  img {
    display: block;
    margin: 20px auto 40px;
  }

  .cancel-button {
    margin: 0 0 0 auto;
    cursor: pointer;
  }

  &-title {
    font-size: 24px;
    color: #000;
    text-align: center;
    margin-bottom: 15px;
  }

  &-subtext {
    color: #746b7f;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
  }

  &-btn {
    display: block;
    margin: auto;
    width: 160px;
    font-size: 20px;
    text-transform: uppercase;
  }
}
