@import '../../styles/constants';

.dashboard-item-component {
  width: 210px;
  height: 223px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.13);
  margin: 0 15px 40px 15px;
  display: inline-block;
  position: relative;

  &:hover {
    transform: scale(1.02);
    z-index: 50;
  }

  .tiles {
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    cursor: pointer;
  }

  .no-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    cursor: pointer;
  }

  .tile-data {
    width: 50%;
    height: 75px;
    opacity: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #ffffff;

    &:nth-child(odd) {
      border-right: 1px solid #ffffff;
    }
  }

  .orange {
    background: $orange-color;
  }

  .yellow {
    background: $yellow-color;
  }

  .red {
    background: $red-color;
  }

  .green {
    background: $green-color;
  }

  .tile-title {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 21px;
    text-align: center;
  }

  .tile-value {
    color: #ffffff;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.32px;
    line-height: 28px;
    text-align: center;
  }

  .info-wrapper {
    background: #fff;
    padding: 12px 20px 13px 20px;
    border-radius: 0 0 10px 10px;
  }

  .info-data {
    position: relative;

    & > div:not(.dropdown) {
      padding-right: 10px;
    }

    & > .dropdown {
      position: absolute;
      right: -4px;
      top: 1px;
    }

    .name {
      color: #232222;
      font-size: 12px;
      letter-spacing: 0.15px;
      line-height: 18px;
      margin-bottom: 2px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date {
      color: #cbcacd;
      font-size: 10px;
      letter-spacing: 0.4px;
      line-height: 13px;
    }

    img {
      vertical-align: top;
    }

    .loader {
      border-top-color: #737373;
    }
  }

  .progress-data {
    display: flex;
    margin-top: 8px;
    justify-content: space-between;

    .progress-wrapper {
      width: 175px;
      height: 5px;
      border-radius: 2px;
      overflow: hidden;
      margin-top: 4px;
      background: #cbcacd;

      .progress-value {
        height: 5px;
        background-color: $green-color;
      }

      .incomplete {
        height: 5px;
        background-color: $orange-color;
      }

      .red {
        height: 5px;
        background-color: $red-color;
      }

      .orange {
        height: 5px;
        background-color: $orange-color;
      }

      .yellow {
        height: 5px;
        background-color: $yellow-color;
      }

      .green {
        height: 5px;
        background-color: $green-color;
      }
    }

    .progress-title {
      color: $green-color;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 13px;
    }

    .progress-title-draft {
      color: #232222;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 13px;
    }

    .progress-title-red {
      color: $red-color;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 13px;
    }

    .progress-title-orange {
      color: $orange-color;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 13px;
    }

    .progress-title-yellow {
      color: $yellow-color;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 13px;
    }

    .progress-title-green {
      color: $green-color;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 13px;
    }
  }

  .clear-button {
    background: url('../../styles/images/more-vertical-gray.svg') center center
      no-repeat;
    vertical-align: top;
    height: 14px;
  }

  @include mobile {
    margin: 20px 0 0 0;
    width: 100%;
    height: 275px;

    .no-image {
      height: 200px;
    }

    .tile-data {
      height: 100px;
    }

    .dropdown-menu {
      right: 0;
      left: auto !important;
      transform: none !important;
      margin-top: 20px;
    }
  }

  .dashboard-item-checkbox {
    position: absolute !important;
    top: -5px;
    right: -5px;
    background: white !important;
    z-index: 5;
    width: 15px;
    height: 15px;
    border-radius: unset !important;
  }

  .item-selected {
    border: 1px solid #883ae1;
    border-radius: 11px;
  }
}
