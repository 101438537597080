@import '../../styles/constants';

.preview {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre {
    margin: 1em 0;
  }

  h1 {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  h6 {
    font-size: 0.75rem;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
  }

  pre {
    font-size: 1rem;
  }

  ul {
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  ol {
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  a {
    color: #a855f7;
    text-decoration: none;
  }

  a:hover {
    color: #7e22ce;
    text-decoration: underline;
  }

  a[target='_blank'] {
    color: #a855f7;
    text-decoration: none;
  }

  a[target='_blank']:hover {
    color: #7e22ce;
    text-decoration: underline;
  }

  @include mobile {
    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

    h3 {
      font-size: 1.5rem;
    }
  }
}

.click-for-help {
  color: #6f2dbd;
  cursor: pointer;
  font-weight: 500;
}

.instructions-template-buttons {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  button {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    background-color: #f3e8ff;
    color: #6b21a8;
    border-radius: 0.25rem;
    transition: background-color 0.2s;

    &:hover {
      background-color: #e9d5ff;
    }
  }
}

textarea {
  @apply w-full border rounded-md p-2 mb-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent;
}

.input-source-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: all 0.2s;

    &.active {
      background-color: #7c3aed;
      color: white;
    }

    &:not(.active) {
      background-color: #e5e7eb;
      color: #374151;

      &:hover {
        background-color: #d1d5db;
      }
    }
  }
}

.input-source-form {
  background-color: #f3f4f6;
  border: 2px dashed #d1d5db;
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-align: center;

  p {
    color: #6b7280;
    margin-bottom: 0.5rem;
  }

  button,
  input {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    transition: all 0.2s;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  button {
    background-color: #7c3aed;
    color: white;

    &:not(:disabled):hover {
      background-color: #6d28d9;
    }
  }

  input {
    border: 1px solid #d1d5db;
    background-color: white;

    &:focus {
      outline: none;
      border-color: #7c3aed;
      box-shadow: 0 0 0 3px rgba(124, 58, 237, 0.1);
    }
  }
}

.draft-generation-modal {
  .modal-content {
    animation: fadeInScale 0.3s ease-out;
  }

  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .animate-bounce {
    animation: bounce 1s infinite;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

.social-media-options {
  .option-label {
    display: flex;
    align-items: center;
    opacity: 0.5;
    cursor: not-allowed;

    input[type='checkbox'] {
      margin-right: 0.5rem;
    }

    .coming-soon {
      margin-left: 0.5rem;
      font-size: 0.75rem;
      color: #6b7280;
    }
  }
}

// Adjust the grid layout for the new 3-column structure
@media (min-width: 768px) {
  .options-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
}

.generate-first-draft-pro {
  .options-grid {
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
    }
  }

  .form-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    textarea {
      flex-grow: 1;
      min-height: 200px;
    }
  }

  .options-column,
  .social-media-column {
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
      flex-grow: 1;
      overflow-y: auto;
    }
  }

  .social-media-options {
    .option-label {
      display: flex;
      align-items: center;
      opacity: 0.5;
      cursor: not-allowed;

      input[type='checkbox'] {
        margin-right: 0.5rem;
      }

      .coming-soon {
        margin-left: 0.5rem;
        font-size: 0.75rem;
        color: #6b7280;
      }
    }
  }
}
