@import '../styles/constants';

.no-match-wrapper {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;

  .row {
    width: 100%;
  }

  img {
    width: 500px;
    max-width: 100%;
  }

  h1 {
    margin-top: 90px;
    font-size: 55px;
    line-height: 55px;
  }

  h2 {
    font-size: 40px;
  }

  p {
    font-size: 16px;
  }

  .btn {
    min-width: 160px;
  }

  @include mobile {
    .text-right {
      text-align: center !important;
      margin-bottom: 40px;
    }

    h1 {
      margin-top: 0;
    }
  }
}
