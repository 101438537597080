@import '../../styles/constants';

.nova-onboarding {
  .nova-bot-img {
    width: 150px;

    &.small {
      width: 100px;
    }
  }

  .guide-content {
    width: 400px;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 0px 35px -3px rgba(29, 32, 34, 0.3);
    border-radius: 12px;

    &.small {
      width: 280px;
    }

    .btn-close {
      position: absolute;
      top: 180px;
      right: -30px;
      cursor: pointer;
    }
  }
}

.guide-video-modal {
  position: relative;
  width: 70vw;
  height: 70vh;
  margin: 40px auto;
  max-width: 100%;
  max-height: 100%;

  @media only screen and (max-width: 524px) {
    width: 100vw;
    height: 50vh;
  }

  iframe {
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 95%;
    height: 85%;
    text-align: center;
    margin: auto;
  }

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.button-highlight {
  :before {
    content: '';
    position: absolute;
    width: 31px;
    height: 25px;
    left: 2px;
    top: 5px;
    border: 1px dashed #7e22ce;
    border-radius: 5px;
  }

  animation: myAnim 2s ease 0s infinite normal forwards;
}

@keyframes myAnim {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(8deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-10deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}
