.upload-area {
  .upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border: 2px dashed #e5e7eb;
    border-radius: 0.5rem;
    background: #f9fafb;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border-color: #9333ea;
      background: #f3f4f6;
    }

    .upload-icon {
      margin-bottom: 1rem;
    }

    .upload-text {
      text-align: center;

      p {
        margin: 0;

        &:first-child {
          color: #111827;
          font-weight: 500;
        }
      }
    }
  }
}
