.modal-wordpress,
.plagiarism-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  max-width: 600px;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 30px;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  .cancel-button {
    display: block;
    margin: 0 0 15px auto;
    cursor: pointer;
  }

  .btn-wrapper {
    text-align: right;
  }

  .form-wordpress {
    .title {
      font-weight: bold;
      font-size: 18px;
    }

    .link-about {
      display: inline-block;
      margin: 15px 0;
    }

    .account-input {
      margin: 0 0 15px 0;
    }
  }
  #submit {
    position: relative;
    margin: 20px 0 0 0;
    width: 170px;
  }

  .spinner-border {
    position: absolute;
    top: calc(50% - 12px);
    right: 24px;
    height: 20px;
    width: 20px;
    border-width: 2px;
  }

  .text-danger {
    margin-top: -10px;
    margin-bottom: 10px;
  }
}

.error-input {
  input {
    border-color: #dc3545;
  }
}
