.integrations-wrapper {
  .add-platform {
    .settings-select {
      width: 100%;
      max-width: 360px;
    }

    #submit {
      background-color: #5dd034;
      border: 1px solid #5dd034;

      &:hover {
        background-color: #fff;
        box-shadow: 0 0 0 0.2rem rgba(#5dd034, 0.25);
        color: #5dd034;
      }
    }
  }

  table {
    width: 100%;
    margin: 40px 0;

    tr {
      height: 60px;
      border-bottom: 1px solid #eee;

      th:last-child {
        width: 115px;
      }
    }

    .added-date {
      @media (max-width: 992px) {
        display: none;
      }
    }

    tbody {
      tr {
        height: 80px;

        th {
          color: #352238;
          font-weight: normal;
        }

        .icon_link {
          .icon {
            display: inline-block;
            width: 30px;
          }

          .platform-link {
            display: inline-block;
            margin: 0 0 0 10px;
          }
        }
      }
    }

    .wrapper_btn {
      display: flex;

      .btn_item {
        margin: 0 15px 0 0;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .platform-item {
    display: flex;
  }
}
