.chat-container {
  display: flex;
  height: calc(100vh - 8rem);
  max-width: 1200px;
  margin: 0 auto;
}

.chat-side-panel-list {
  width: 300px;
  max-height: 400px;
  @apply shadow-lg;
  @apply bg-gray-100;
  @apply rounded-xl;

  div {
    @apply border-b;
    @apply border-gray-200;
  }
}

.chat-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  max-width: 1200px;
  margin: 0 auto;

  .panel & {
    width: 500px;
  }
}

.chat-messages {
  overflow-y: auto;
  min-height: 60vh;
  overflow-anchor: none;
  position: relative;
  padding: 0 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 0 15%;
  }
}

.scroll-btn {
  position: fixed;
  bottom: 10rem;
  right: 5rem;
}

.chat-message {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  max-width: 75%;
  width: fit-content;
  margin: 1.5rem 0;
  position: relative;
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
  box-sizing: border-box;

  &.chat-message-user {
    margin-left: auto;
  }

  &.chat-message-bot {
    margin-right: auto;
  }

  .panel & {
    max-width: 85%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .chat-message {
    max-width: 85%;
  }
}

.chat-message-user,
.chat-message-bot {
  display: flex;
  gap: 12px;
  width: 100%;
}

.chat-message-user {
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0;
  flex-direction: row-reverse;
  align-self: flex-end;
  text-align: right;
  width: auto;
}

.chat-message-user .chat-message-content {
  align-items: flex-end;
  margin-left: auto;
  width: auto !important;
}

.chat-message-user .chat-message-text {
  background-color: #8b5cf6;
  color: white;
  border-bottom-right-radius: 4px;
  margin-left: auto;
  text-align: left;

  a {
    color: #f3f4f6;
    text-decoration: underline;
  }
}

.chat-message-user .chat-message-meta {
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
}

.chat-message-user .chat-message-actions {
  order: 1;
  display: flex;
  justify-content: flex-end;
}

.chat-message-user .chat-message-time {
  order: 2;
  text-align: right;
}

.chat-message-bot {
  justify-content: flex-start;
  margin-right: auto;
  margin-left: 0;
  align-self: flex-start;
}

.chat-message-user img,
.chat-message-bot img {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.chat-message-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: calc(100% - 50px);
  width: fit-content;

  .panel & {
    max-width: calc(100% - 50px);
  }
}

.chat-message-text {
  background-color: #f3f4f6;
  padding: 14px 16px;
  border-radius: 18px;
  color: #111827;
  margin-bottom: 0.25rem;
  overflow: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: fit-content;
  max-width: 100%;

  .chat-message-bot & {
    border-bottom-left-radius: 4px;
  }

  .chat-message-user & {
    background-color: #8b5cf6;
    color: white;
    border-bottom-right-radius: 4px;
    margin-left: auto;
    text-align: left;

    a {
      color: #f3f4f6;
      text-decoration: underline;
    }
  }

  code {
    @apply block;
    @apply p-2;
    @apply bg-gray-700;
    @apply rounded-lg;
    @apply my-2;
    @apply font-bold;
    @apply text-gray-200;
    width: fit-content;
    font-family: 'Courier New', Courier, monospace;
  }

  ul,
  ol,
  dl {
    @apply px-5;
    margin: 0.5rem 0;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  p {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.chat-message-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  width: 100%;
}

.chat-message-time {
  font-size: 0.75rem;
  color: #6b7280;
}

.chat-message-actions {
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .panel & {
    @apply mr-2;
  }
}

.chat-message:hover .chat-message-actions {
  opacity: 1;
}

.action-buttons-container {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f9fafb;
  border-radius: 16px;
  padding: 2px 6px;
  margin-right: -4px;
}

.chat-message-user .action-buttons-container {
  background-color: #f3f4f6;
}

.chat-action-icon {
  cursor: pointer;
  color: #4b5563;
  transition: color 0.2s ease-in-out;
  margin: 0 0.3rem;

  &:hover {
    color: #8b5cf6;
    transform: scale(1.15);
  }

  &.favorite {
    color: #f59e0b;
  }
}

/* Ensure solid star icon has correct color */
.action-buttons-container .favorite {
  color: #f59e0b !important;
}

.chat-message-edit {
  background-color: white;
  color: #111827;
  outline: none;
  width: 40dvw;
  border: 1px solid rgba(211, 211, 211, 0.6);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  resize: vertical;
  font-size: 14px;
  line-height: 1.5;

  .panel & {
    max-width: 95%;
  }

  &:focus {
    box-shadow: 0 2px 6px rgba(139, 92, 246, 0.2);
    border-color: rgba(139, 92, 246, 0.4);
  }
}

.chat-action-save {
  cursor: pointer;
  color: green;
  opacity: 0.7;
  transition: opacity 0.3s;
  margin: 0rem 0.5rem;
}

.chat-action-cancel {
  cursor: pointer;
  color: red;
  opacity: 0.7;
  transition: opacity 0.3s;
  margin: 0rem 0.5rem;
}

.chat-input-container {
  background-color: transparent;
  border-top: 1px solid rgba(229, 231, 235, 0.5);
  padding: 0.5rem 1rem 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-top: auto;
}

.chat-input-wrapper {
  position: relative;
  max-width: 100%;
}

.textarea-container {
  position: relative;
  width: 100%;
}

.chat-input {
  resize: none;
  min-height: 44px;
  max-height: 200px;
  width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 1.5rem;
  padding: 12px 48px 12px 16px;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  background-color: white;
  outline: none;
  overflow-y: auto;
  line-height: 1.5;
  vertical-align: middle;
  word-wrap: break-word;
  white-space: pre-wrap;

  &:focus {
    border-color: #8b5cf6;
    box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.2);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.chat-input-actions {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  z-index: 10;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .chat-send-button {
    background-color: #8b5cf6;
    color: white;
    border: none;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;

    &:hover {
      background-color: #7c3aed;
      transform: scale(1.05);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: #c4b5fd;
    }
  }
}

pre {
  margin: 16px 0;
  border-radius: 8px;
  overflow: hidden;
  font-size: 1rem;
  font-family: Arial, sans-serif;
  color: #111827;
  white-space: pre-wrap;
}

.code-block-container {
  margin: 16px 0;
  border-radius: 8px;
  overflow: hidden;
}

.code-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2d3748;
  padding: 8px 16px;
  font-size: 12px;
  color: #e2e8f0;
}

.code-copy-button {
  background: transparent;
  border: none;
  color: #e2e8f0;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.code-copy-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.chat-input textarea:focus {
  border-color: rgba(139, 92, 246, 0.5);
  box-shadow: 0 2px 4px rgba(139, 92, 246, 0.2);
}

.new-chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #8b5cf6;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
  width: 100%;
  position: sticky;
  top: -1rem;
  z-index: 10;
}

.new-chat-button:hover {
  background-color: #7c3aed;
}

.chat-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-right: 1px solid #e5e7eb;
  overflow-y: auto;
  position: relative;
}

.chat-left-panel::-webkit-scrollbar {
  width: 10px;
  @apply bg-gray-800;
}

.chat-left-panel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(122, 122, 122, 0.3);
  background-color: #939393;
}

.chat-dropdown {
  position: relative;
  display: inline-block;
}

.chat-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9fafb;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  padding: 10px;
}

.chat-dropdown-content a {
  color: #111827;
  text-decoration: none;
  display: block;
  padding: 5px 10px;
}

.chat-dropdown-content a:hover {
  background-color: #f3f4f6;
}

.chat-dropdown:hover .chat-dropdown-content {
  display: block;
}

.chat-dropdown:hover .chat-dropdown-button {
  background-color: #f3f4f6;
}

.chat-empty-state {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chat-empty-state-card {
  background-color: #e5e7eb;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.panel {
    width: 250px;
    height: auto;
    @apply p-3;
    @apply text-sm;
  }
}

.chat-empty-state-card h3 {
  margin-bottom: 0.5rem;
}

.chat-empty-state-card p {
  font-size: 0.8rem;
}

.card-header {
  transition: background-color 0.3s, color 0.3s;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card {
  margin-bottom: 1rem;
}

.content-item {
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.content-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-item.active {
  background-color: #a6a6a6;
}

.chat-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e5e7eb;
  overflow-y: auto;
  position: relative;
}

.delete-icon {
  cursor: pointer;
  font-size: 0.8em;
  opacity: 0;
  transition: color 0.3s, opacity 0.3s;
}

.delete-icon.visible {
  opacity: 1;
}

//

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.form-container {
  text-align: center;
  width: 100%;
}

.header {
  font-size: 4rem;
  margin-bottom: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}

.btn {
  background-color: #6c757d;
  color: #ffffff;
  border: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn:hover {
  background-color: #5a6268;
}

.header-navbar {
  padding: 0.5rem 1rem;
}

.bramework-icon-white {
  width: 125px;
  object-fit: cover;
  margin-bottom: 3rem;
}

.bramework-logo-white {
  width: 150px;
  object-fit: cover;
}

.nova-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.app-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .form {
    flex-direction: row;
    gap: 0.5rem;
  }

  .form-group {
    flex-grow: 1;
  }
}

.chat-feature-buttons {
  padding: 0.35rem 0 0.25rem;
  margin-top: 0.35rem;
  border-top: 1px solid rgba(229, 231, 235, 0.5);

  .feature-button {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    padding: 0.25rem 0.65rem;
    border-radius: 0.375rem;
    font-size: 0.8125rem;
    color: #6b7280;
    background-color: rgba(243, 244, 246, 0.8);
    transition: all 0.2s ease;
    border: 1px solid transparent;

    &:hover {
      background-color: rgba(229, 231, 235, 0.8);
      color: #4b5563;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &.recording {
      background-color: rgba(239, 68, 68, 0.1);
      color: #ef4444;
      border-color: rgba(239, 68, 68, 0.3);
      animation: pulse 1.5s infinite;
    }

    &.loading {
      background-color: rgba(139, 92, 246, 0.1);
      color: #8b5cf6;
      border-color: rgba(139, 92, 246, 0.3);
    }
  }

  .ai-agent-toggle {
    .agent-label {
      display: flex;
      align-items: center;
      font-size: 0.8125rem;
      color: #6b7280;
      cursor: pointer;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 18px;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
          background-color: #8b5cf6;
        }

        &:checked + .slider:before {
          transform: translateX(14px);
        }

        &:disabled + .slider {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #e5e7eb;
        transition: 0.3s;
        border-radius: 20px;

        &:before {
          position: absolute;
          content: '';
          height: 14px;
          width: 14px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          transition: 0.3s;
          border-radius: 50%;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(239, 68, 68, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
  }
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d1d5db;
  transition: 0.3s;
  border-radius: 20px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.3s;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
}

input:checked + .slider {
  background-color: #8b5cf6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8b5cf6;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

input:disabled + .slider {
  opacity: 0.6;
  cursor: not-allowed;
}

.ai-agent-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 10px;
  border-radius: 6px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f3f4f6;
  }

  .agent-label {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    font-weight: 500;
    color: #4b5563;
  }
}

/* Tooltip styling enhancement */
.MuiTooltip-tooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  font-size: 12px !important;
  padding: 6px 10px !important;
  border-radius: 4px !important;
}

/* Styles for chat input in builder mode */
.chat-input-container.in-builder {
  background-color: white;
  border-top: 1px solid #e5e7eb;
  padding: 0.75rem;
  margin-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.chat-input-wrapper.builder-input-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.textarea-container.builder-textarea {
  position: relative;
  width: 100%;
  min-height: 38px;
}

.chat-input.in-builder {
  min-height: 38px;
  padding: 8px 40px 8px 12px;
  border-radius: 8px !important;
  font-size: 0.9rem;
  line-height: 1.4;
  display: block;
  border: 1px solid #e5e7eb;
  background-color: white;
  width: 100%;
  resize: none;
  box-shadow: none;
}

.chat-input-actions.in-builder {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.chat-send-button.in-builder {
  width: 30px;
  height: 30px;
}

.chat-feature-buttons.in-builder {
  padding: 0.25rem 0;
  margin-top: 0.25rem;
}

.in-builder .feature-button {
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
}

/* Fix the chat container in builder view */
#side-panel .chat-container {
  height: auto;
  min-height: 300px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#side-panel .chat-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#side-panel .chat-messages {
  height: 380px;
  min-height: 380px;
  padding: 0 5%;
  overflow-y: auto;
}

#side-panel .chat-input {
  resize: none !important;
  min-height: 38px !important;
  max-height: 100px !important; /* About 4 rows */
  overflow-y: auto !important; /* Always allow scrolling */
  white-space: pre-wrap !important; /* Preserve line breaks */
  line-height: 1.5 !important;
  padding: 8px 55px 8px 12px !important;
  font-size: 14px !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 6px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  transition: height 0.1s ease !important;
}

#side-panel .in-builder {
  margin: 0 !important;
  padding: 10px !important;
  border-top: 1px solid #e5e7eb !important;
  width: 100% !important;
}

#side-panel .chat-main {
  padding-bottom: 0 !important;
}

/* Ensure our messages are visible */
#side-panel .chat-messages {
  height: 400px !important;
  max-height: 400px !important;
  min-height: auto !important;
  padding: 0 10px !important;
  overflow-y: auto !important;
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
}

/* Fix the chat bubble appearance in side panel */
#side-panel .chat-message {
  width: auto !important;
  max-width: 90% !important;
  margin: 0.75rem 0 !important;
}

#side-panel .chat-message-text {
  max-width: 90% !important;
  width: auto !important;
  padding: 10px 12px !important;
  font-size: 0.9rem !important;
  word-break: break-word !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
}

#side-panel .chat-messages {
  height: calc(100% - 100px) !important;
  min-height: 300px !important;
  max-height: 550px !important;
  padding: 0 15px !important;
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important;
}

/* Ensure the chat container in builder takes full height */
#side-panel .chat-container {
  height: 100% !important;
  min-height: 400px !important;
  width: 100 !important;
  display: flex !important;
  flex-direction: column !important;
}

#side-panel .chat-main {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
}

/* Ensure input field is fully visible and properly sized */
#side-panel .textarea-container {
  min-height: 40px !important;
  margin-bottom: 5px !important;
}

#side-panel .chat-input {
  height: 38px !important;
  min-height: 38px !important;
  padding: 8px 40px 8px 16px !important;
  background-color: #f5f7f9 !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 8px !important;
  font-size: 0.9rem !important;
  line-height: 1.5 !important;
  display: block !important;
  width: 100 !important;
  box-shadow: none !important;
  resize: none !important;
}

/* Fix layout of buttons */
#side-panel .chat-feature-buttons {
  margin-top: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-top: 1px solid rgba(229, 231, 235, 0.5) !important;
}

#side-panel .feature-button {
  font-size: 0.75rem !important;
  padding: 0.2rem 0.5rem !important;
  background-color: #f5f7f9 !important;
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
}

/* Fix send button position */
#side-panel .chat-send-button {
  position: absolute !important;
  right: 8px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 999 !important;
  width: 30px !important;
  height: 30px !important;
  background-color: #7c3aed !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  border: none !important;
  cursor: pointer !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 1 !important;
  visibility: visible !important;
}

#side-panel .chat-send-button svg {
  transform: rotate(90deg) !important;
  width: 14px !important;
  height: 14px !important;
  color: white !important;
  margin-left: 2px !important;
  margin-top: -2px !important;
}

/* Simplified toolbar for builder */
#side-panel .chat-feature-buttons {
  display: flex !important;
  justify-content: space-between !important;
  padding: 4px 0 !important;
  margin-top: 8px !important;
  height: auto !important;
  border-top: 1px solid rgba(229, 231, 235, 0.5) !important;
}

/* Make builder UI ultra simple */
#side-panel .feature-button {
  background-color: #f3f4f6 !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  margin-right: 4px !important;
  height: 24px !important;
  font-size: 11px !important;
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
}

#side-panel .feature-button span {
  font-size: 11px !important;
}

#side-panel .ai-agent-toggle {
  background-color: #f3f4f6 !important;
  border-radius: 4px !important;
  padding: 2px 8px !important;
  height: 24px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 11px !important;
  margin-left: auto !important;
}

/* Ensure textarea is fully visible */
#side-panel .chat-input {
  resize: none !important;
  min-height: 38px !important;
  height: 38px !important;
  padding: 8px 45px 8px 12px !important;
  font-size: 13px !important;
  line-height: 1.5 !important;
  background-color: white !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 6px !important;
  width: 100% !important;
  box-shadow: none !important;
  margin: 0 !important;
}

/* Scroll to top button for builder panel */
#side-panel .scroll-to-top-button {
  position: fixed !important;
  bottom: 80px !important;
  right: 15px !important;
  z-index: 100 !important;
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f3f4f6 !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #e5e7eb !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
}

#side-panel .scroll-to-top-button:hover {
  background-color: #e5e7eb !important;
  transform: translateY(-2px) !important;
}

#side-panel .scroll-to-top-button svg {
  width: 16px !important;
  height: 16px !important;
  color: #6b7280 !important;
}

/* Voice recording and improve button states for builder */
#side-panel .feature-button.recording {
  background-color: rgba(239, 68, 68, 0.15) !important;
  border: 1px solid rgba(239, 68, 68, 0.3) !important;
  color: #ef4444 !important;
}

#side-panel .feature-button.loading {
  background-color: rgba(139, 92, 246, 0.15) !important;
  border: 1px solid rgba(139, 92, 246, 0.3) !important;
  color: #8b5cf6 !important;
}

#side-panel .feature-button:disabled {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}

#side-panel .feature-button.recording svg,
#side-panel .feature-button.loading svg {
  color: currentColor !important;
}

/* Final fixes for builder chat panel layout */
#side-panel .chat-input-wrapper {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
}

#side-panel .chat-input {
  width: 100% !important;
  box-sizing: border-box !important;
  padding-right: 40px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: calc(100% - 5px) !important;
}

#side-panel .chat-send-button {
  position: absolute !important;
  right: 8px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 999 !important;
  width: 30px !important;
  height: 30px !important;
  background-color: #7c3aed !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  border: none !important;
  cursor: pointer !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 1 !important;
  visibility: visible !important;
}

#side-panel .chat-send-button svg {
  transform: rotate(90deg) !important;
  width: 14px !important;
  height: 14px !important;
  color: white !important;
  margin-left: 2px !important;
  margin-top: -2px !important;
}

/* Enhance markdown rendering in chat messages */
#side-panel .chat-message-bot .chat-message-text {
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 600 !important;
    line-height: 1.25 !important;
  }

  & h1 {
    font-size: 1.2rem !important;
  }

  & h2 {
    font-size: 1.1rem !important;
  }

  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 1rem !important;
  }

  & p {
    margin-bottom: 0.75rem !important;
  }

  & ul,
  & ol {
    margin-top: 0.25rem !important;
    margin-bottom: 0.75rem !important;
    margin-left: 1.5rem !important;
  }

  & ul {
    list-style-type: disc !important;
  }

  & ol {
    list-style-type: decimal !important;
  }

  & li {
    margin-bottom: 0.25rem !important;
  }

  & a {
    color: #8b5cf6 !important;
    text-decoration: underline !important;
  }

  & code {
    background-color: rgba(0, 0, 0, 0.05) !important;
    border-radius: 3px !important;
    padding: 0.1rem 0.3rem !important;
    font-family: monospace !important;
    font-size: 0.85em !important;
    white-space: pre-wrap !important;
  }

  & pre {
    background-color: rgba(0, 0, 0, 0.05) !important;
    border-radius: 3px !important;
    padding: 0.75rem !important;
    margin-bottom: 1rem !important;
    overflow-x: auto !important;

    & code {
      background-color: transparent !important;
      padding: 0 !important;
      white-space: pre !important;
    }
  }

  & blockquote {
    border-left: 3px solid #e5e7eb !important;
    padding-left: 0.75rem !important;
    color: #6b7280 !important;
    margin: 0.75rem 0 !important;
  }

  & table {
    border-collapse: collapse !important;
    margin-bottom: 1rem !important;
    width: 100% !important;

    & th,
    & td {
      border: 1px solid #e5e7eb !important;
      padding: 0.5rem !important;
    }

    & th {
      background-color: #f3f4f6 !important;
      font-weight: 600 !important;
    }
  }
}

/* Fix code blocks in markdown */
#side-panel .code-block-container {
  margin: 1rem 0 !important;
  border-radius: 6px !important;
  overflow: hidden !important;
}

#side-panel .code-block-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0.5rem 1rem !important;
  background-color: #282c34 !important;
  color: #abb2bf !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
}

#side-panel .code-copy-button {
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
  background: none !important;
  color: #abb2bf !important;
  border: none !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-size: 0.75rem !important;
  transition: all 0.2s !important;
}

#side-panel .code-copy-button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

#side-panel pre {
  margin: 0 !important;
  padding: 1rem !important;
  overflow-x: auto !important;
  font-size: 0.85rem !important;
}

#side-panel code {
  font-family: 'SF Mono', Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace !important;
}

/* Consistent popover styling for all dropdowns */
.chat-side-panel-popover {
  max-height: 250px !important;
  width: 260px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  background-color: #f9fafb !important;
  border-radius: 0.5rem !important;
  z-index: 50 !important;
  overflow: hidden !important;
  margin-top: 8px !important;
}

.chat-side-panel-header {
  padding: 0.5rem !important;
  font-weight: 500 !important;
  color: #374151 !important;
  border-bottom: 1px solid #e5e7eb !important;
  background-color: white !important;
}

.chat-side-panel-content {
  max-height: 220px !important;
  overflow-y: auto !important;
}

.chat-side-panel-item {
  padding: 0.5rem 0.75rem !important;
  cursor: pointer !important;
  transition: background-color 0.2s ease !important;
  border-bottom: 1px solid #e5e7eb !important;
}

.chat-side-panel-item:hover {
  background-color: #f3f4f6 !important;
}

.chat-side-panel-item.active {
  background-color: #f3e8ff !important;
  border-left: 4px solid #8b5cf6 !important;
  padding-left: 0.5rem !important;
}

.chat-side-panel-empty {
  padding: 1rem !important;
  text-align: center !important;
  color: #6b7280 !important;
  font-size: 0.875rem !important;
}

.chat-side-panel-empty-subtitle {
  margin-top: 0.25rem !important;
  font-size: 0.75rem !important;
  color: #9ca3af !important;
}

/* Fix for the chat container wrapper */
.chat-panel-wrapper {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
}

/* Fix text overflow in messages more aggressively */
#side-panel .chat-message-text {
  width: auto !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  white-space: normal !important;
}

/* Ensure textarea doesn't exceed container bounds */
#side-panel .chat-input {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  padding-right: 40px !important;
  overflow: hidden !important;
  white-space: pre-wrap !important; /* Allow wrapping in the textarea */
}

/* Chat messages wrapper container */
.chat-messages-wrapper {
  width: 510px !important;
  max-width: 510px !important;
  box-sizing: border-box !important;
  overflow-x: hidden !important;
  padding: 0 10px !important;
}

/* Make sure message text content doesn't overflow */
#side-panel .chat-message-text {
  max-width: 100% !important;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  white-space: normal !important;
  hyphens: auto !important;
  font-size: 0.9rem !important;
}

/* Fix Markdown rendering to respect container width */
#side-panel .chat-message-text pre,
#side-panel .chat-message-text code,
#side-panel .chat-message-text table {
  max-width: 100% !important;
  box-sizing: border-box !important;
  overflow-x: auto !important;
}

/* Ensure maximum content width for MessageItem component */
#side-panel .chat-message-content .markdown-content {
  max-width: 100% !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  overflow: hidden !important;
}

/* Fix overflow for specific elements that might cause issues */
#side-panel .chat-message-text pre,
#side-panel .chat-message-text code,
#side-panel .chat-message-text table,
#side-panel .chat-message-text a {
  max-width: 100% !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  white-space: pre-wrap !important;
}

/* Force container width to be respected */
.chat-messages-wrapper {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  overflow-x: hidden !important;
  padding: 0 10px !important;
}

/* Ensure message container size is constrained */
#side-panel .chat-message {
  width: auto !important;
  max-width: 490px !important;
  box-sizing: border-box !important;
}

/* Fix for message content wrapper */
.message-content-wrapper {
  width: 400px !important;
  max-width: 400px !important;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  overflow: hidden !important;
}

/* Further fix for the ReactMarkdown component inside MessageItem */
#side-panel .markdown-content > div {
  width: 100% !important;
  max-width: 100% !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  overflow: hidden !important;
}

/* Ensure links don't overflow */
#side-panel .markdown-content a {
  word-break: break-all !important;
  max-width: 100% !important;
  display: inline-block !important;
}

/* Force max width on all elements in the chat message */
#side-panel .chat-message * {
  max-width: 100% !important;
}

/* Set fixed dimensions for outer container */
.chat-panel-wrapper {
  max-width: 400px !important; /* Fixed max width */
  width: 100% !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
}

/* Set chat container dimensions */
#side-panel .chat-container {
  max-width: 400px !important; /* Match parent container */
  width: 100% !important;
  box-sizing: border-box !important;
}

/* Ensure content in messages doesn't overflow */
#side-panel .chat-message-content {
  max-width: 300px !important; /* Fixed width */
  width: auto !important;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  overflow: hidden !important;
}

/* Fix paragraph and text wrapping in messages */
#side-panel .chat-message-text p,
#side-panel .chat-message-text span,
#side-panel .chat-message-text div,
#side-panel .chat-message-text li {
  max-width: 280px !important; /* Slightly smaller than parent */
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

/* Fix for Markdown content */
#side-panel .markdown-content > div,
#side-panel .markdown-content p {
  max-width: 280px !important; /* Match other text elements */
  word-break: break-word !important;
}

/* Fix links and long URLs */
#side-panel a {
  word-break: break-all !important;
  max-width: 280px !important;
  display: inline-block !important;
}

/* Fix the empty state message */
#side-panel .text-center.py-10.text-gray-500 {
  max-width: 280px !important;
  margin: 0 auto !important;
  padding: 40px 10px !important;
  box-sizing: border-box !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

/* Set fixed width for user messages */
#side-panel .chat-message-user .chat-message-text {
  max-width: 360px !important;
  width: auto !important;
  box-sizing: border-box !important;
}

/* Set fixed width for bot messages */
#side-panel .chat-message-bot .chat-message-text {
  max-width: 360px !important;
  width: auto !important;
  box-sizing: border-box !important;
}

/* Ensure message time doesn't cause overflow */
#side-panel .chat-message-time {
  max-width: 280px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/* Fix textarea to prevent overflow */
#side-panel textarea.chat-input {
  width: 300px !important;
  max-width: 300px !important;
  box-sizing: border-box !important;
  padding-right: 45px !important;
}

/* Main panel sizing */
#side-panel {
  width: 340px !important;
  max-width: 340px !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
}

/* Fixed container width for panel */
#side-panel .w-full.fixed.h-full {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
}

/* Messages container width */
.chat-messages-wrapper {
  width: 540px !important;
  max-width: 540px !important;
  box-sizing: border-box !important;
  overflow-x: hidden !important;
  padding: 0 10px !important;
}

/* Chat container width */
#side-panel .chat-container {
  width: 540px !important;
  max-width: 540px !important;
  box-sizing: border-box !important;
}

/* Override text width */
#side-panel .chat-message-text p,
#side-panel .chat-message-text span,
#side-panel .chat-message-text div,
#side-panel .chat-message-text li,
#side-panel .markdown-content p,
#side-panel .markdown-content div {
  width: 460px !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

/* Override text area width */
#side-panel textarea.chat-input {
  width: 480px !important;
  max-width: 480px !important;
  box-sizing: border-box !important;
  padding-right: 40px !important;
  border-radius: 6px !important;
}

/* Ensure buttons in feature bar are properly sized and visible */
#side-panel .feature-button {
  display: flex !important;
  align-items: center !important;
  gap: 3px !important;
  padding: 2px 6px !important;
  background-color: #f3f4f6 !important;
  border-radius: 4px !important;
  font-size: 10px !important;
  color: #4b5563 !important;
  border: 1px solid #e5e7eb !important;
  height: 22px !important;
  white-space: nowrap !important;
}

/* Ensure feature buttons container has proper spacing */
#side-panel .chat-feature-buttons {
  display: flex !important;
  justify-content: space-between !important;
  padding-top: 5px !important;
  border-top: 1px solid rgba(229, 231, 235, 0.5) !important;
  margin-top: 8px !important;
  width: 480px !important;
}

/* Fix feature buttons right container */
#side-panel .feature-buttons-right {
  display: flex !important;
  align-items: center !important;
  margin-left: auto !important;
  gap: 8px !important;
}

/* Style for editor toggle */
#side-panel .editor-toggle {
  background-color: #f3f4f6 !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
  border: 1px solid #e5e7eb !important;
  height: 24px !important;
}

#side-panel .editor-toggle span {
  font-size: 11px !important;
  color: #4b5563 !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}

/* Simplified chat input container */
#side-panel .chat-input-container {
  width: 100% !important;
  padding: 10px 15px !important;
  box-sizing: border-box !important;
}

#side-panel .chat-input-wrapper {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

#side-panel .chat-input {
  flex: 1 !important;
  height: 38px !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 6px !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  outline: none !important;
  box-sizing: border-box !important;
}

/* Ensure the send button is visible */
#side-panel button {
  visibility: visible !important;
  display: flex !important;
}

/* Make sure the container is visible */
#side-panel .chat-input-container {
  padding: 10px 15px !important;
  border-top: 1px solid #e5e7eb !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

/* Fix the paper airplane icon */
#side-panel .chat-input-container .h-5 {
  height: 1.25rem !important;
  width: 1.25rem !important;
  color: white !important;
}

/* Force send button to be visible */
.send-button-visible {
  visibility: visible !important;
  opacity: 1 !important;
  display: flex !important;
  pointer-events: auto !important;
  z-index: 9999 !important;
  position: relative !important;
}

/* Ensure button is not hidden by any parent styles */
#side-panel .send-button-visible {
  visibility: visible !important;
  opacity: 1 !important;
  display: flex !important;
  pointer-events: auto !important;
}

/* Override any disabled state styling */
#side-panel .send-button-visible:disabled {
  opacity: 1 !important;
  pointer-events: auto !important;
}

/* Ensure close button is visible */
#side-panel .flex-col > div:first-child button {
  position: relative !important;
  z-index: 100 !important;
  visibility: visible !important;
  opacity: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 4px !important;
  right: 0 !important;
  top: 0 !important;
}

/* Make the X icon visible */
#side-panel .flex-col > div:first-child button svg {
  color: #6b7280 !important;
  width: 16px !important;
  height: 16px !important;
  visibility: visible !important;
  opacity: 1 !important;
}

/* Make feature buttons extra small like in main chat */
#side-panel .feature-button {
  background-color: #f3f4f6 !important;
  border-radius: 4px !important;
  padding: 2px 6px !important;
  margin-right: 4px !important;
  height: 20px !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  gap: 3px !important;
}

#side-panel .feature-button svg {
  width: 10px !important;
  height: 10px !important;
}

#side-panel .feature-button span {
  font-size: 10px !important;
}

/* Typing indicator animation */
.typing-indicator {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background-color: #f3f4f6;
  border-radius: 12px;
  width: fit-content;

  span {
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #8b5cf6;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.4;
    animation: typing 1.4s infinite both;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes typing {
  0% {
    transform: translateY(0);
    opacity: 0.4;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0.4;
  }
}

/* Builder panel specific styles for action buttons */
#side-panel .chat-message-actions {
  margin-top: 4px;
  opacity: 0;
}

#side-panel .chat-message:hover .chat-message-actions {
  opacity: 1;
}

#side-panel .action-buttons-container {
  background-color: transparent;
  padding: 0;
  margin: 0;
}

#side-panel .chat-action-icon {
  width: 16px;
  height: 16px;
}

/* Make edit text area fit into panel width */
.chat-panel-wrapper .chat-message-edit {
  max-width: 260px !important;
  width: 100% !important;
}
