@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../styles/constants';

html,
body {
  font-family: 'Roboto', 'Segoe UI', 'Arial', sans-serif !important;
  background-color: #f9fafb;
}
