.EmailChangeModalView {
  padding: 20px;

  h2 {
    color: #1a051d;
    font-size: 18px;
    font-weight: bold;
    line-height: 13px;
    margin-bottom: 32px;
  }

  .button-wrapper {
    padding-top: 40px;
  }
}

.EmailChangeModalView-button {
  display: block;
  margin: 0 0 15px auto;
  margin-top: 8px;

  .cancel-button {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}
