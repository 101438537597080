@import '../styles/constants';

.dashboard-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;

  .side-menu {
    padding: 48px 10px;
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 200;
    width: 300px;
    background-color: #180929;
    will-change: width;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

    & > div {
      height: 19px;
    }

    .logo {
      background: url('../styles/images/logo-dashboard.svg') 0 0 no-repeat;
      width: 164px;
      height: 19px;
      // width: 185px;
      // height: 21px;
      display: inline-block;
      cursor: pointer;
      margin-left: 16px;
    }

    .toggle-control {
      background-image: url('../styles/images/menu-control.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #180929;
      width: 38px;
      height: 38px;
      display: inline-block;
      cursor: pointer;
      position: absolute;
      right: 23px;
      top: 41px;
      z-index: 2;
      border-radius: 19px;
      will-change: right;
    }

    ul {
      list-style: none;
      margin: 67px 0 0 0;
      padding: 0;

      li {
        display: block;
        margin-bottom: 8px;

        span {
          font-size: 11px;
          font-style: italic;
          color: #b721ff;
        }

        a {
          color: white;
          display: block;
          text-decoration: none;
          border-radius: 4px;
          padding: 12px 10px 10px 62px;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 1px;
          border-left: 5px solid transparent;
          position: relative;
          overflow: hidden;

          &::before {
            content: '';
            position: absolute;
            background-position: center center;
            background-repeat: no-repeat;
            width: 20px;
            height: 21px;
            left: 22px;
            top: 11px;
            transition: left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
            will-change: left;
          }

          &.dashboard::before {
            background-image: url('../styles/images/dashboard.svg');
          }

          &.drafts::before {
            left: 26px;
            background-image: url('../styles/images/drafts.svg');
          }

          &.keywords::before {
            left: 24px;
            background-image: url('../styles/images/keywords.svg');
          }

          &.news::before {
            left: 24px;
            background-image: url('../styles/images/product-release.svg');
          }

          &.affiliates::before {
            left: 24px;
            background-image: url('../styles/images/money-transfer.svg');
          }

          &.tutorials::before {
            left: 24px;
            background-image: url('../styles/images/video-tutorial.svg');
          }

          &.performance::before {
            left: 24px;
            background-image: url('../styles/images/analytics.svg');
          }

          &.outlines::before {
            left: 24px;
            background-image: url('../styles/images/blog-outline.svg');
          }

          &.titles::before {
            left: 24px;
            background-image: url('../styles/images/blog-title.svg');
          }
        }

        &.active a {
          background-color: rgba(255, 255, 255, 0.4);
          border-left-color: #f5f6fa;
          box-shadow: 0 2px 5px 0 rgba(83, 97, 255, 0.12);
        }
      }
    }
  }

  .content {
    width: 100%;
    padding-left: 300px;
    will-change: padding-left;
    transition: padding-left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    background-color: #fbfbfb;

    & > .body {
      background-color: #fbfbfb;
    }
  }

  @include not-mobile {
    &.collapsed {
      .side-menu {
        width: 70px;

        .logo {
          display: none;
        }

        li a {
          padding-left: 45px;
          padding-right: 0;
          height: 44px;

          &.dashboard:before {
            left: 11px;
          }

          &.keywords:before {
            left: 12px;
          }

          &.news:before {
            left: 12px;
          }

          &.affiliates:before {
            left: 12px;
          }

          &.drafts:before {
            left: 13px;
          }

          &.tutorials:before {
            left: 12px;
          }

          &.performance:before {
            left: 12px;
          }

          &.outlines:before {
            left: 12px;
          }

          &.titles:before {
            left: 12px;
          }
        }
      }

      .content {
        padding-left: 70px;
      }

      .toggle-control {
        right: 13px;
        transform: rotate(180deg);
      }
    }
  }

  @include mobile {
    padding-top: 60px;

    .content {
      padding-left: 0;
    }

    .side-menu {
      padding: 20px 10px;
      left: -300px;
      will-change: left;
      transition: left 0.3s cubic-bezier(0.1, 0, 0, 1) 0s;

      ul {
        margin-top: 30px;
      }

      .toggle-control {
        top: 11px;
        right: -50px;
        transform: rotate(180deg);
        background-image: url('../styles/images/menu-control-dark.svg');
        background-color: transparent;
      }
    }

    &.toggle {
      &:before {
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(25, 9, 42, 0.7);
        z-index: 2;
      }

      .side-menu {
        left: 0;

        .toggle-control {
          transform: rotate(0deg);
          background-image: url('../styles/images/menu-control.svg');
        }
      }
    }
  }
}
