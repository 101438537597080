.rewrite-generator-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.rewrite-generator-form {
  width: 100%;

  @media (min-width: 768px) {
    width: 33.333%;
  }
}

.rewrite-generator-results {
  width: 100%;

  @media (min-width: 768px) {
    width: 66.667%;
  }
}

.rewrite-generator-actions {
  margin-top: 1rem;
}

.nova-robot-container {
  position: relative;
  width: 96px;
  height: 96px;
}

.nova-robot {
  position: absolute;
  top: 0;
  left: 0;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.loading-text {
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.char-count-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  .form-label {
    flex: 1;
    min-width: 0;
  }

  .char-count {
    flex-shrink: 0;
  }
}
