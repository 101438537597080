$mobile-width: 768px;

$lightest-bg: #fbfbfb;
$color-pink: #b721ff;
$main-contrast: #6f2dbd;
// $tooltip-bg: #3f3356;
$tooltip-bg: #374151;
$green-color: #24cf33;
$red-color: #ff006d;
$blue-color: #24cecf;
$yellow-color: #ffbe0b;
$orange-color: #fb5607;
$black-color: #1a051d;
$gray-color: #acabad;
$lightgray-color: #dcdcdc;

@mixin mobile {
  @media screen and (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin not-mobile {
  @media screen and (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile-h {
  @media screen and (max-height: #{$mobile-width - 1px}) {
    @content;
  }
}
