@import '../styles/constants';
@import '../styles/auth-wrapper';

.forgot-password-wrapper {
  h1 {
    margin-bottom: 25px !important;
  }

  h2 {
    color: #000000;
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }

  form {
    width: 415px;
    max-width: 100%;
    margin: 0 auto;
  }

  .sign-in-wrapper {
    text-align: center;
    margin-top: 20px;

    a {
      font-size: 15px;
      letter-spacing: 0.6px;
      line-height: 18px;
    }
  }
}
