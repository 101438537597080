// BlogPostImprover.scss
@import '../../styles/constants';

.blog-post-improver {
  .preview {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    pre {
      margin: 1em 0;
      max-width: 100%;
    }

    h1 {
      font-size: 2rem;
      text-align: center;
      font-weight: 600;
    }

    h2 {
      font-size: 1.75rem;
      font-weight: 600;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    h4 {
      font-size: 1.25rem;
      font-weight: 600;
    }

    h5 {
      font-size: 1rem;
      font-weight: 600;
    }

    h6 {
      font-size: 0.75rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      margin-bottom: 1em;
    }

    pre {
      font-size: 1rem;
      background-color: #f3f4f6;
      padding: 1em;
      border-radius: 0.25rem;
      overflow-x: auto;
      max-width: 100%;
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    ul,
    ol {
      list-style-type: disc;
      margin: 1em 0;
      padding-left: 20px;
      max-width: 100%;
    }

    ol {
      list-style-type: decimal;
    }

    a {
      color: #a855f7;
      text-decoration: none;
      word-break: break-word;

      &:hover {
        color: #7e22ce;
        text-decoration: underline;
      }

      &[target='_blank'] {
        color: #a855f7;
        text-decoration: none;

        &:hover {
          color: #7e22ce;
          text-decoration: underline;
        }
      }
    }

    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .left-side-container {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    box-sizing: border-box;

    @media (min-width: 1024px) {
      width: 50%;
    }
  }

  .collapsible-sections-container {
    min-height: calc(100vh - 100px);
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .content-gaps {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    .collapsible-section {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      margin-bottom: 1rem;
      border: 1px solid #e5e7eb;
      border-radius: 0.5rem;

      .section-header {
        width: 100%;
        padding: 0.75rem 1rem;
        background-color: #f3f4f6;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        h2 {
          margin: 0;
          font-size: 1.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          padding-right: 1rem;
        }
      }

      .section-content {
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
      }
    }
  }

  .click-for-help {
    color: #6f2dbd;
    cursor: pointer;
    font-weight: 500;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 90%;
    height: 90%;
    max-width: 1200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    button {
      padding: 0.5rem 1rem;
      margin: 0 0.5rem;
      border: none;
      background-color: #f3f4f6;
      color: #374151;
      border-radius: 0.25rem;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #e5e7eb;
      }

      &.active {
        background-color: #8b5cf6;
        color: white;
      }
    }
  }

  .tab-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
  }

  .comparison-view .comparison-controls {
    margin-bottom: 20px;
  }

  .comparison-view .show-changes-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .comparison-view .show-changes-label input[type='checkbox'] {
    margin-right: 10px;
  }

  .comparison-view .checkbox-text {
    padding-left: 5px;
  }

  .comparison-view .comparison-section {
    margin-bottom: 20px;
  }

  .comparison-view h3 {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .improvement-summary {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #f3f4f6;
    border-radius: 0.5rem;

    h3 {
      margin-bottom: 0.5rem;
    }

    .summary-item {
      margin-bottom: 1rem;

      h4 {
        margin-bottom: 0.25rem;
      }

      p {
        margin: 0;
      }

      .positive {
        color: #10b981;
      }

      .negative {
        color: #ef4444;
      }
    }
  }

  .merge-button,
  .close-button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .merge-button {
    background-color: #4caf50;
    color: white;

    &:hover {
      background-color: #45a049;
    }
  }

  .close-button {
    background-color: #8b5cf6;
    color: white;

    &:hover {
      background-color: #7c3aed;
    }
  }

  @include mobile {
    .modal-content {
      padding: 1rem;
    }

    h1 {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    h3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .comparison-view {
      flex-direction: column;
    }
  }
}
