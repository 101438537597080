.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text {
  margin-left: 10px;
  font-size: 14px;
}

.loading-dots {
  display: flex;
  justify-content: space-between;
  width: 24px;
}

.loading-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: loadingDot 1.4s infinite;
}

.loading-dot:nth-child(2) {
  animation-delay: -0.7s;
}

.loading-dot:nth-child(3) {
  animation-delay: -0.35s;
}

@keyframes loadingDot {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
