@import '../styles/constants';

.auth-wrapper {
  height: 100%;
  padding: 40px 50px;
  max-width: 1440px;
  margin: 0 auto;
  background: $lightest-bg;

  header {
    height: 65px;
    position: relative;
    z-index: 1;
    font-size: 0;

    span,
    a {
      font-size: 18px;
      padding: 9px 0 10px 0;
      display: inline-block;
      margin-top: 9px;
      vertical-align: middle;
    }

    .btn {
      min-width: 152px;
      margin-left: 43px;
      padding-top: 9px;
      padding-bottom: 10px;
      border-radius: 3px;
      letter-spacing: 0.02em;
    }

    @include mobile {
      text-align: center;

      a,
      .btn {
        margin: 0;
      }

      div {
        text-align: left;
      }

      .btn {
        float: right;
      }

      .logo {
        margin: 0 0 15px -15px;
      }

      .logo-icon {
        margin: 1px 0 0 -70px;
        width: 88px;
        height: 55px;
      }
    }
  }

  main {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -65px;
    position: relative;
    padding-top: 65px;
    width: 100%;

    h1 {
      text-align: center;
      color: $main-contrast;
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 60px;
      margin-top: 10px;
      font-weight: normal;
      letter-spacing: 2px;
    }

    .form {
      max-width: 100%;

      .btn {
        margin-top: 40px;
      }
    }

    @include mobile {
      h1 {
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 40px;
      }

      .btn.btn-lg {
        margin-top: 30px;
      }
    }

    @include mobile-h {
      h1 {
        margin-bottom: 35px;
      }

      .btn.btn-lg {
        margin-top: 25px;
      }
    }
  }

  @include mobile {
    padding: 30px;
  }
}
