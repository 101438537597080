body.introjs-fixParent {
  overflow: hidden;
}

.footer-side-panel-close {
  width: 100%;
}

.footer-side-panel-open {
  width: calc(100% - 32rem);
}

.footer-plagiarism-panel-open {
  width: calc(100% - 32rem);
}

.post-builder-component {
  //margin: 0 25px 25px 25px;
  padding: 0 0 40px 0;
  min-height: 100%;
  background: #f7f7f7;

  .header-wrapper {
    height: 45px;
    max-width: 1210px;
    margin: 0 auto;
    border-radius: 0 0 10px 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 30px 40px 43px;
    box-sizing: content-box;
    position: fixed;
    top: 0;
    left: 20px;
    right: 20px;
    z-index: 2;
    will-change: padding;
    transition: padding 0.3s linear;

    .btn {
      width: 135px;
      line-height: 29px;
      transition: all 0.3s linear;
    }

    &.introjs-fixParent {
      position: absolute;
    }
  }

  .arrow-white {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4.5px;
    margin-bottom: 3px;
    margin-left: 8px;
  }

  .arrow-purple {
    border: solid #6f2dbd;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4.5px;
    margin-bottom: 3px;
    margin-left: 8px;
  }

  .arrow-black {
    border: solid #1a051d;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4.5px;
    margin-bottom: 3px;
    margin-left: 8px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .create-post-wrapper {
    padding-top: 145px;
  }

  .create-post-inner {
    max-width: 1290px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 15px;
  }

  .seo-tools-wrapper {
    position: sticky;
    top: 85px;
    width: 23%;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 10px;
    box-sizing: border-box;
  }

  .post-content-wrapper {
    width: 76%;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 40px 25px;
    box-sizing: border-box;
    // HM added to position hover div for steps
    position: relative;
  }

  .footer {
    height: 45px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }

  .btn {
    padding: 6px 6px;
  }

  // common blocks styles

  .autocomplete-heading {
    width: 100%;
    border: 0.5px solid #cbcacd;
    border-radius: 6px;
    background-color: rgba(111, 45, 189, 0);
    margin-top: 18px;
    color: #000000;
    font-family: Roboto, sans-serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 34px;
    padding: 0 15px 0 20px;

    input {
      width: 100%;
      border: none;
      height: 55px;
      outline: none;
      box-sizing: border-box;
      background-color: rgba(111, 45, 189, 0);

      &:focus {
        border-bottom: 1px solid #cbcacd;
      }
    }

    ul {
      list-style-type: none;
      text-align: left;
      margin: 0;
      padding: 0;

      &::before {
        content: '';
      }
    }

    .suggestions-list {
      font-size: 22px;
      padding: 8px 5px;
      cursor: pointer;

      &:hover {
        color: #6f2dbd;
      }
    }

    .suggestions-no-list {
      font-size: 22px;
      padding: 8px 5px;
      pointer-events: none;
      opacity: 0.3;
    }

    /* li {
      font-size: 22px;
      padding: 8px 5px;
      cursor: pointer;

      &:hover {
        color: #6f2dbd;
      }
    } */

    & > div {
      max-height: 200px;
      overflow: auto;
    }
  }

  .autocomplete-paragraph {
    width: 100%;
    color: #000000;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.17px;
    line-height: 20px;
    border: 0.5px solid #cbcacd;
    border-radius: 6px;
    margin-top: 18px;
  }

  .autocomplete-paragraph textarea {
    height: 155px;
    width: 100%;
    resize: none;
    border: none;
    outline: none;
    box-sizing: border-box;
    background-color: rgba(111, 45, 189, 0);
    padding: 10px 15px 10px 20px;
  }

  .autocomplete-paragraph ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0 15px 0 20px;
    border-top: 1px solid #cbcacd;
  }

  .autocomplete-paragraph ul::before {
    content: '';
  }

  .autocomplete-paragraph li {
    font-size: 15px;
    padding: 10px 5px;
    cursor: pointer;
    border-top: 1px solid #cbcacd;
  }

  .autocomplete-paragraph li:first-child {
    border-top: 0;
  }

  .autocomplete-paragraph li:hover {
    color: #6f2dbd;
  }

  /* textarea {
    height: 160px;
    width: 100%;
    color: #000000;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.17px;
    line-height: 20px;

    box-sizing: border-box;
    border: 0.5px solid #cbcacd;
    border-radius: 6px;
    background-color: rgba(111, 45, 189, 0);
    margin-top: 18px;
    padding: 10px 15px 10px 20px;
    resize: none;
  } */

  textarea::placeholder {
    margin-top: 35px;
    font-size: 15px;
    font-style: italic;
    letter-spacing: 0.17px;
    line-height: 20px;
  }

  // input {
  //   height: 55px;
  // }

  /* input {
    box-sizing: border-box;
    width: 100%;
    border: 0.5px solid #cbcacd;
    border-radius: 6px;
    background-color: rgba(111, 45, 189, 0);
    margin-top: 18px;
    color: #000000;
    font-family: Roboto, sans-serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 34px;
    padding: 10px 15px 10px 20px;
  } */

  // input:focus,
  // textarea:focus {
  //   border: 1px solid #6f2dbd;
  // }

  .main-block-wrapper {
    &:hover {
      .add-button {
        display: block;
        height: 40px;
        width: 40px;
        background-image: url('../styles/images/add-plus.svg');
        margin: auto;
      }

      .add-step-button {
        background-repeat: no-repeat;
        display: block;
        height: 28px;
        width: 28px;
        background-image: url('../styles/images/add-plus-small.svg');
        margin-top: 20px;
        margin-left: 7px;
      }

      .remove-step-button {
        background-repeat: no-repeat;
        display: block;
        height: 28px;
        width: 28px;
        background-image: url('../styles/images/delete-trash-small.svg');
        margin-top: 20px;
        margin-left: 18px;
      }

      .block-action-wrapper {
        // width: 150px;
        width: 130px;
        height: 40px;
        position: relative;
        float: right;
        margin-right: 0px;
        top: 16px;
        display: block;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0px 0px 8px 0px rgba(203, 202, 205, 1);
        padding: 5px 0px;

        .create-content-button {
          width: 77px;
          height: 25px;
          background-repeat: no-repeat;
          background-image: url('../styles/images/create-content-button.svg');
          position: relative;
          margin-left: 12px;
          top: -2px;
          display: inline-block;
          // transition: all 0.2s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }

        .block-up-button {
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
          background-image: url('../styles/images/block-arrow-up.svg');
          position: relative;
          margin-left: 10px;
          top: 3px;
          display: inline-block;

          &:hover {
            transform: scale(1.1);
          }
        }

        .block-down-button {
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
          background-image: url('../styles/images/block-arrow-down.svg');
          position: relative;
          margin-left: 12px;
          top: 3px;
          display: inline-block;

          &:hover {
            transform: scale(1.1);
          }
        }

        .remove-block-button {
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
          background-image: url('../styles/images/delete-trash.svg');
          position: relative;
          margin-left: 8px;
          display: inline-block;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .block-header {
    color: #000000;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    letter-spacing: 0.27px;
    line-height: 32px;
    margin: 0;
  }

  .block-comment {
    color: #746b7f;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.17px;
    line-height: 20px;
    margin: 0;
  }

  .no-relevant-content {
    color: #6f2dbd;
    cursor: pointer;
    font-weight: 500;
  }

  .block-image-wrapper {
    box-sizing: border-box;
    border: 0.5px solid #cbcacd;
    border-radius: 6px;
    background-color: rgba(111, 45, 189, 0);
    height: 192px;
    margin-top: 18px;
    text-align: center;
    overflow: auto;
    display: flex;
    flex: 1;
    justify-content: center;

    .del-button {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      // background: #ff2183;
      background-image: url('../styles/images/round-red-cancel.svg');
      position: relative;
      margin-left: -10px;
      margin-top: -10px;
      display: block;
    }

    .block-image-inner {
      display: flex;
      align-items: center;
    }

    &.not-empty {
      justify-content: flex-start;

      .file-selector {
        margin: 0 20px;
      }

      /* HM Added 5-10-20 for 3 button selection box */
      .file-selector-container {
        margin: 0 20px;
      }
    }

    .image-wrapper {
      margin: 0 20px;
    }

    img {
      height: 137px;
      width: 244px;
      margin-top: -10px;
      border-radius: 6px;
      display: block;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .file-selector {
    box-sizing: border-box;
    height: 137px;
    width: 244px;
    border: 1px dashed #746b7f;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      border: 1px dashed #6f2dbd;
    }
  }

  /* Start HM Added 5-10-20 for 3 button selection box */
  .file-selector-container {
    box-sizing: border-box;
    height: 137px;
    width: 244px;
    // border: 1px dashed #746b7f;
    // border-radius: 6px;
    // background-color: #ffbf00;
    // position: relative;
  }

  .file-select {
    box-sizing: border-box;
    height: 28%;
    width: 100%;
    border: 1px dashed #746b7f;
    border-radius: 6px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .file-library {
    height: 28%;
    width: 100%;
    border: 1px dashed #6f2dbd;
    border-radius: 6px;
    margin: 11px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .file-suggestion {
    height: 28%;
    width: 100%;
    border: 1px dashed #ff006e;
    border-radius: 6px;
    margin: 11px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  /* End */

  .icon-description {
    color: #746b7f;
    font-size: 12px;
    letter-spacing: 0.14px;
    line-height: 16px;
    text-align: center;
  }

  .title-block {
    margin-bottom: 40px;
  }

  // HM updated 7-17-20 see main-block-wrapper
  // .block-up-button {
  //   width: 30px;
  //   height: 30px;
  //   background-repeat: no-repeat;
  //   background-image: url('../styles/images/block-arrow-up.svg');
  //   position: relative;
  //   margin-left: 16px;
  //   top: 4px;
  //   display: inline-block;
  // }

  // HM updated 7-17-20 see main-block-wrapper
  // .block-down-button {
  //   width: 30px;
  //   height: 30px;
  //   background-repeat: no-repeat;
  //   background-image: url('../styles/images/block-arrow-down.svg');
  //   position: relative;
  //   margin-left: 16px;
  //   top: 4px;
  //   display: inline-block;
  // }

  // HM updated 7-17-20 see main-block-wrapper
  // .remove-block-button {
  //   width: 30px;
  //   height: 30px;
  //   background-repeat: no-repeat;
  //   background-image: url('../styles/images/delete-trash.svg');
  //   position: relative;
  //   margin-left: 16px;
  //   display: inline-block;
  // }

  .more-button {
    width: 30px;
    height: 7px;
    background-repeat: no-repeat;
    background-image: url('../styles/images/more-horizontal-gray.svg');
    position: relative;
    float: right;
    margin-right: 16px;
    top: 16px;
    display: block;
  }

  .add-block-wrapper {
    // height: 52px;
    height: 30px;
    padding: 5px 0;
    // margin-top: -22px;
    .add-button {
      display: none;

      &:hover {
        transform: scale(1.15);
      }
    }

    // HM updated 7-17-20 see main-block-wrapper
    /*
    &:hover {
      .add-button {
        display: block;
        height: 40px;
        width: 40px;
        //border-radius: 15px;
        //background: #24cf33;
        background-image: url('../styles/images/add-plus.svg');
        margin: auto;
      }
    }
    */
  }

  .add-step-block-wrapper {
    height: 100px;
    width: 40px;
    left: 0px;
    right: 0px;
    position: absolute;

    .add-step-button {
      display: none;

      &:hover {
        transform: scale(1.15);
      }
    }

    // HM updated 7-17-20 see main-block-wrapper
    /*
    &:hover {
      .add-step-button {
        background-repeat: no-repeat;
        display: block;
        height: 30px;
        width: 30px;
        //border-radius: 15px;
        background-image: url('../styles/images/add-plus-small.svg');
        margin-top: 20px;
        margin-left: 12px;
      }
    }*/
  }

  .remove-step-block-wrapper {
    height: 100px;
    width: 40px;
    right: 0px;
    position: absolute;

    .remove-step-button {
      display: none;

      &:hover {
        transform: scale(1.15);
      }
    }

    // HM updated 7-17-20 see main-block-wrapper
    /*
    &:hover {
      .remove-step-button {
        background-repeat: no-repeat;
        display: block;
        height: 30px;
        width: 30px;
        //border-radius: 15px;
        background-image: url('../styles/images/delete-trash-small.svg');
        margin-top: 20px;
        margin-left: 12px;
      }
    }
    */
  }

  .done-dropdown .dropdown-menu button {
    padding: 8px 16px 8px 30px;
  }

  .tutorial-link {
    padding-left: 30px;
  }

  .export-btn {
    position: relative;

    &::before {
      content: '';
      border: solid #3f3356;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      position: absolute;
      left: 13px;
      top: 13px;
    }

    &:hover::before {
      border-color: #6f2dbd;
    }

    div {
      display: none;
      position: absolute;
      left: -115px;
      top: 0;
      padding-right: 10px;
      width: 115px;

      ul {
        width: 100%;
        list-style: none;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 #979797;
        margin: 0;
        padding: 5px 0;

        a {
          padding: 5px 16px;
        }
      }
    }

    &:hover div {
      display: block;
    }
  }

  .standard-btn {
    position: relative;

    &::before {
      content: '';
      border: solid #3f3356;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(315deg);
      -webkit-transform: rotate(315deg);
      position: absolute;
      right: 15px;
      top: 15px;
    }

    &:hover::before {
      border-color: #6f2dbd;
    }

    div {
      display: none;
      position: absolute;
      left: 150px;
      top: 0;
      padding-left: 17px;
      width: 130px;

      ul {
        width: 100%;
        list-style: none;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 #979797;
        margin: 0;
        padding: 5px 0;
      }
    }

    &:hover div {
      display: block;
    }
  }
}

.y-scrolling {
  .post-builder-component .header-wrapper {
    padding: 20px 40px 13px 40px;
    box-shadow: 0 2px 4px 0 #979797;

    .btn {
      width: 100px;
      padding: 5px 10px;
      height: 40px;
      line-height: 1;
      border-radius: 7px !important;
    }
  }
  .content-action-wrapper {
    position: sticky;
    top: 80px;
  }
}

@media (min-width: 993px) {
  .content-action-wrapper {
    background-color: rgba(255, 255, 255, 0.97);
    border: solid rgba(255, 255, 255, 0);
    border-width: 2px;
    width: 100%;
    z-index: 3;
  }

  .content-action-inner {
    display: inline-block;
  }

  .ai-power-wrapper-opacity {
    opacity: 0;
  }

  .ai-power-wrapper {
    padding: 10px 0 10px;
    background-color: rgba(255, 255, 255, 0.65);
    // border-radius: 10px;
    margin-left: 12px;
    // margin-bottom: 8px;
    margin-top: -20px;
    text-align: center;
    width: 150px;
    color: #746b7f;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.17px;
    line-height: 20px;

    .ai-power-value {
      color: #ff006e;
      font-weight: 600;
    }
    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }
  }

  .write-more-button {
    margin: 12px 12px 0 0;

    .ai-power-value {
      color: #ff006e;
      font-size: 12px;
      font-weight: 600;
      margin-left: 6px;
    }

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -8px;
      height: 12px;
      width: 12px;
      border-width: 2px;
    }

    button {
      width: 143px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }

  .redo-button {
    margin: 12px 12px 0 0;

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -12px;
      height: 20px;
      width: 20px;
      border-width: 2px;
    }

    button {
      width: 62px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }

  .outline-button {
    margin: 12px 12px 0 0;

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -8px;
      height: 12px;
      width: 12px;
      border-width: 2px;
    }

    button {
      width: 205px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }

  .create-titles-button {
    margin: 12px 12px 0 0;

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -8px;
      height: 12px;
      width: 12px;
      border-width: 2px;
    }

    button {
      width: 194px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }

  .version-button {
    margin: 12px 12px 0 0;

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    button {
      width: 132px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 2px;
    }
  }

  .create-list-button {
    margin: 12px 12px 0 0;

    .selection-count-value {
      color: #ff006e;
      font-weight: 600;
      margin-left: 6px;
    }

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -8px;
      height: 12px;
      width: 12px;
      border-width: 2px;
    }

    button {
      width: 148px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }

  .provide-steps-button {
    margin: 12px 12px 0 0;

    .selection-count-value {
      color: #ff006e;
      font-weight: 600;
      margin-left: 6px;
    }

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -8px;
      height: 12px;
      width: 12px;
      border-width: 2px;
    }

    button {
      width: 160px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }

  .paragraph-button {
    margin: 12px 12px 0 0;

    .selection-count-value {
      color: #ff006e;
      font-weight: 600;
      margin-left: 6px;
    }

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -8px;
      height: 12px;
      width: 12px;
      border-width: 2px;
    }

    button {
      width: 170px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }

  .rewrite-text-button {
    margin: 12px 12px 0 0;

    .selection-count-value {
      color: #ff006e;
      font-weight: 600;
      margin-left: 6px;
    }

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -8px;
      height: 12px;
      width: 12px;
      border-width: 2px;
    }

    button {
      width: 142px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }

  .summarize-text-button {
    margin: 12px 12px 0 0;

    .selection-count-value {
      color: #ff006e;
      font-weight: 600;
      margin-left: 6px;
    }

    .tip-component {
      width: 14px;
      padding: 0 4px;
      line-height: 16px;
      font-size: 12px;
    }

    .spinner-border {
      position: relative;
      top: -1.5px;
      right: -8px;
      height: 12px;
      width: 12px;
      border-width: 2px;
    }

    button {
      width: 162px;
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      border-width: 1px;
    }
  }
}

@media (max-width: 992px) {
  .y-scrolling {
    .btn {
      width: 100%;
    }
    .content-action-wrapper {
      position: sticky;
      top: 98px;
    }
  }

  .content-action-wrapper {
    width: 100%;
    z-index: 3;
  }

  .post-preview-component,
  .post-builder-component {
    div.header-wrapper {
      height: auto;
      flex-wrap: wrap;
      padding: 15px 40px 13px 40px;
      box-shadow: 0 2px 4px 0 #979797;

      button {
        padding: 5px 10px;
        height: 45px;
        line-height: 1;
        border-radius: 7px !important;
      }

      a:nth-child(1),
      button:nth-child(1) {
        width: 45% !important;
        order: 1;
      }

      .dropdown-more {
        order: 2;
        width: 45%;

        button {
          width: 100% !important;
        }
      }

      .progress-component {
        height: auto;
        order: 0;
        margin: -4px 0px 7px 0px;
        padding: 0 5px;
        font-size: 15px;

        .progress-container {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          height: auto;

          .progress-title {
            width: auto;
            margin: auto;
            text-align: right;
            padding-right: 0px;
            font-size: 17px;
          }

          .seo-row {
            .seo-title {
              width: auto !important;
              margin: auto;
              text-align: right;
              padding-right: 0;
              font-size: 17px;
            }

            .seo-progress-wrapper {
              width: 0;
              height: 0;
            }
          }

          .post-progress-wrapper {
            width: 0;
            height: 0;
          }

          .post-progress-value {
            font-size: 17px;
          }

          .word-count {
            margin: auto;
            text-align: center;
            font-size: 17px;
            align-content: space-around;
          }

          .target-word-count {
            margin: auto;
            text-align: center;
            font-size: 17px;
            align-content: space-around;
          }
        }
      }
    }

    .create-post-wrapper {
      padding-top: 130px;
    }

    .create-post-inner {
      flex-direction: column-reverse;

      .post-content-wrapper {
        width: 100%;
        padding: 20px;
      }

      .seo-tools-wrapper {
        position: inherit;
        width: 100%;
        margin: 0 0 20px 0;
      }
    }

    .post-content-wrapper {
      padding: 0;
    }
  }
}

@media (max-width: 576px) {
  .y-scrolling {
    .content-action-wrapper {
      position: sticky;
      top: 173px;
    }
  }

  .content-action-wrapper {
    width: 100%;
    z-index: 3;
  }
  .post-builder-component {
    .header-wrapper {
      padding: 15px;
    }

    .create-post-wrapper {
      padding-top: 255px;
    }
  }

  .spinner-border {
    position: relative;
    top: -1px;
    right: -12px;
    height: 18px;
    width: 18px;
    border-width: 2px;
  }

  .post-preview-component,
  .post-builder-component {
    div.header-wrapper {
      a:nth-child(1),
      button:nth-child(1) {
        width: 100% !important;
        margin: 0 0 5px 0;
      }

      .dropdown-more {
        width: 100%;
      }
    }
  }

  .buttons-wrapper {
    padding: 10px !important;

    .container {
      display: flex;
      justify-content: space-between;
      padding: 0;

      .left-button,
      .right-button {
        float: none;
        width: 45%;

        button {
          width: 100% !important;
        }
      }
    }
  }
}
