@import '../../styles/constants';

body.ReactModal__Body--open {
  overflow-y: hidden;
}

.file-select {
  display: block;
  background: $green-color url('../../styles/images/add-plus.svg') 2px 3px
    no-repeat;
  margin: 20px 0;
  padding: 8px 16px 8px 16px;
  border-radius: 3px;
  color: #ffffff;

  &:focus {
    outline: none;
  }
}

.library-image-modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(255, 255, 255);
  overflow: scroll;
  outline: none;

  .header-wrapper {
    position: relative;
    height: 70px;
    padding: 0 20px;

    h1 {
      color: #1a051d;
      font-size: 38px;
      padding-top: 15px;
      line-height: 55px;
      margin: 0;
    }

    .clear-button {
      position: relative;
      top: 20px;
    }
  }

  .container-fluid {
    height: 100%;
    margin-top: -70px;
    padding: 100px 20px 20px 20px;

    & > div,
    & > div > div {
      //height: 100%;
    }

    .side-menu {
      & > span {
        display: block;
        color: $gray-color;
        padding-top: 5px;
        text-align: center;
      }

      ul {
        display: block;
        min-width: 10rem;
        padding: 0.5rem 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        background: #fff;
        box-shadow: 0 2px 4px 0 #979797;

        li {
          display: block;
          width: 100%;
          text-align: left;
          color: #3f3356;
          font-size: 20px;
          line-height: 25px;
          padding: 8px 16px;
          border: 0 none;
          background: transparent;
          cursor: pointer;

          &.active,
          &:hover {
            color: #6f2dbd;
          }

          &.active {
            cursor: default;
          }
        }
      }
    }

    form {
      background: white;
      z-index: 3;
      position: relative;
      display: block;
      height: 65px;

      .input-group .input-group-append .btn {
        border-radius: 0 0.3rem 0.3rem 0 !important;
        height: 48px;
        line-height: 29px;
      }
    }

    .images-wrapper {
      padding-top: 65px;
      margin-top: -65px;
      height: 100%;
      overflow: auto;

      .images {
        display: flex;
        flex-wrap: wrap;
      }

      .load-more {
        margin: 10px 0;

        .btn {
          width: 320px;
        }
      }
    }
  }

  input {
    height: 45px;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    border: 0.5px solid #cbcacd;
    border-radius: 6px;
    background-color: rgba(111, 45, 189, 0);
    margin: 0px 0px 10px 0px;
    color: #000000;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 34px;
    padding: 5px 10px 5px 10px;
  }

  input:focus {
    border: 1px solid #6f2dbd;
  }

  .buttons-wrapper {
    //background-color: pink;
    display: flex;
    margin: 10px 0px 0px 0px;
    justify-content: space-between;

    .btn {
      width: 145px;
    }
  }

  .warning {
    color: $orange-color;
    font-size: 16px;
  }

  .image-list {
    display: inline-block;
    width: 20%;
    position: relative;
    padding: 10px;
    cursor: pointer;

    .img {
      width: 100%;
      height: 180px;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      border: 1px solid $lightgray-color;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      position: relative;

      .check,
      .actions,
      .MuiCircularProgress-root {
        display: none;
      }

      .check {
        position: absolute;
        left: 0;
        top: 0;
      }

      .name {
        color: white;
        font-size: 16px;
        letter-spacing: 0.15px;
        line-height: 18px;
        padding: 4px;
        bottom: -64px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.2);

        a {
          color: white;
        }

        a:hover {
          color: rgba(255, 255, 255, 0.8);
          text-decoration: none;
        }
      }

      .actions {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -15px;
        text-align: center;

        & > a {
          width: 40px;
          height: 40px;
          border-radius: 6px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          vertical-align: top;
          background-repeat: no-repeat;
          background: white;
          margin: 0 3px;
          box-shadow: 0 2px 4px 0 #0000009c;
        }

        .check-mark-icn {
        }
      }

      &:hover {
        .actions {
          display: block;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.37);
        }
      }

      &.selected {
        .check {
          display: inline-block;
        }

        .check-mark-icn svg {
          fill: $green-color;
        }
      }

      &.loading {
        .MuiCircularProgress-root {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -20px 0 0 -20px;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 1;
          background-color: rgba(255, 255, 255, 0.58);
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .library-image-modal {
    h1 {
      font-size: 30px;
    }

    .image-list {
      width: 33%;
    }
  }
}

@media (max-width: 768px) {
  .library-image-modal {
    .header-wrapper {
      h1 {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 576px) {
  .library-image-modal {
    .image-list {
      width: 50%;
    }
  }
}
