@import '../styles/constants';

.MuiCircularProgress-colorPrimary {
  color: $main-contrast !important;
}

.loading {
  padding: 20px 0;

  .loading-inner {
    margin: auto;
    width: 40px;
  }
}
