@import '../styles/constants';

$width: 1050px;

.new-post-component {
  min-height: 100%;
  display: flex;
  padding: 25px 25px 100px 25px;
  flex-direction: column;

  .tip-component {
    margin-left: 15px;
  }

  .inner {
    max-width: $width;
    width: auto;
    margin: auto;
  }

  .not-empty {
    margin: 0 auto;

    & > h2 {
      display: none;
    }
  }

  h1 {
    color: $black-color;
    font-size: 42px;
    line-height: 64px;
    font-weight: bold;
    margin-left: -5px;
    margin-top: 10px;
    text-align: center;
  }

  h2 {
    color: $black-color;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    margin: 0;
  }

  .new-post-input-wrapper {
    box-sizing: border-box;
    max-width: $width;
    // border: 1px solid $main-contrast;
    // border-radius: 15px;
    // background-color: $main-contrast;
    margin-top: 45px;
  }

  form {
    // display: flex;
    justify-content: stretch;
  }

  .new-post-input-wrapper input {
    width: 100%;
    border-radius: 8px;
    background-color: #fbfbfb;
    // border: none;
    border: 1px solid $gray-color;
    padding: 0 40px;
    font-size: 20px;
    color: $black-color;
  }

  input::placeholder {
    opacity: 0.3;
    color: $black-color;
  }

  .new-post-input-wrapper button {
    margin-left: 25px;
    width: 170px;
  }

  .powered {
    text-align: center;
    font-size: 14px;
    color: #6d6d6d;
    // font-style: italic;
  }

  .loading-wrapper {
    margin-top: 50px;
    color: $black-color;
    font-size: 22px;
    text-align: center;
  }

  .suggested-keywords {
    margin: 40px auto 36px;
    font-size: 24px;
    font-weight: normal;
    line-height: 28px;
    text-align: center;

    .title {
      font-weight: bold;
      color: #6d6d6d;
    }

    .suggested-item {
      color: #2c67c2;
    }
  }

  .analyzer-results {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .form-card {
    padding: 30px;
  }

  .main-card {
    width: auto;
    max-width: $width;
    margin: 30px auto;
  }

  .keyword-ideas {
    .keyword-ideas__wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .button-wrapper {
      padding: 10px 0 0 0;
    }

    .keyword-ideas__item {
      border: 0.5px solid #979797;
      border-radius: 10px;
      padding: 15px 25px;
      overflow: hidden;
      width: 48%;
      margin: 10px 0;

      &:hover {
        border: 0.5px solid $main-contrast;
      }

      .title {
        margin: 0 0 5px 0;
        font-size: 16px;
        letter-spacing: 0.21px;
        line-height: 24px;
        color: $main-contrast;
      }

      .keyword-value {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .keyword-value__item {
          display: flex;
          justify-content: space-between;
          margin: 0 5px 0 0;
          font-size: 12px;
          color: #9b9b9b;

          &:last-child {
            margin: 0;
          }

          span {
            &:first-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .questions {
    .questions-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .button-wrapper {
      padding: 10px 0 0 0;
    }

    .w-200 {
      width: 200px;
    }
  }

  .outline-generator-icon {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 5px;
    left: -65px;
  }

  .question-item {
    padding: 15px 25px;

    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 24px;
    color: $black-color;

    overflow: hidden;
    width: 48%;
    margin: 10px 0;

    /* Neutral/White */

    background: #ffffff;
    /* Neutral/300 */

    border: 1px solid #e2e8f0;
    border-radius: 11px;

    &:hover {
      border: 0.5px solid $main-contrast;
    }
  }

  .write-post-button {
    display: block;
    margin: 10px 0 0 0;
    font-size: 10px;
    border-width: 1px;
    font-weight: 600;
  }

  .post-item-component {
    &:hover {
      border: 0.5px solid $main-contrast;
    }
  }

  .buttons-wrapper {
    width: $width;
    max-width: 100%;
    height: 100px;
    margin: auto;
  }

  .action-buttons-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .action-button {
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 16px;
    min-width: 120px;
    text-align: center;

    &:hover,
    &:focus {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.save-keyword {
      background-color: #ede9fe;
      color: #7c3aed;
      border: 1px solid #7c3aed; // Added purple border

      &:hover,
      &:focus {
        background-color: #ddd6fe;
      }
    }

    &.new-post {
      background-color: #7c3aed;
      color: white;
      border: none;

      &:hover,
      &:focus {
        background-color: #6d28d9;
      }
    }
  }

  .left-button {
    width: 50%;
    height: 100px;
    float: left;
  }

  .right-button {
    height: 100px;
    text-align: right;
  }

  .posts-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .trends-value-wrapper {
    display: flex;
    overflow: auto;
    justify-content: space-between;
  }

  .no-data {
    margin-top: 10px;
  }

  .no-result-wrapper {
    font-size: 20px;
    color: #6d6d6d;

    @media (max-width: 576px) {
      font-size: 16px;
    }

    .title {
      font-weight: normal;
    }

    .list-suggestions {
      max-width: 420px;
      margin: 0 auto;
      text-align: left;

      .title-list {
        margin: 10px 0;
      }

      .list {
        line-height: 35px;
      }
    }

    span {
      color: #000;
    }

    .no-result-help {
      color: #6f2dbd;
      cursor: pointer;
      font-weight: 500;
    }
  }

  .buttons-wrapper {
    height: 45px;
    border-radius: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    will-change: padding;
    transition: padding 0.3s linear;
    padding: 15px 40px 13px 40px;
    box-shadow: 0 2px 4px 0 #979797;
    margin: 0;
    width: auto;
  }

  button {
    position: relative;

    .spinner-border {
      position: relative;
      top: 4px;
      height: 20px;
      width: 20px;
      border-width: 2px;
    }
  }
}

@media (max-width: $width) {
  .new-post-component {
    .questions-wrapper {
      display: block;

      .question-item {
        width: 47%;
      }
    }

    .posts-wrapper {
      display: block;

      .post-item-component {
        margin: 14px auto;
        width: auto;

        &:hover {
          border: 0.5px solid $main-contrast;
        }
      }
    }

    .inner {
      width: 100%;
    }
  }
}

@include mobile {
  .new-post-component {
    padding: 20px 20px 95px 20px;

    .clear-button img {
      width: 25px;
      height: 25px;
    }

    h1 {
      font-size: 36px;
      margin-top: 8px;
      margin-bottom: 0;
    }

    h2 {
      line-height: 26px;
    }

    h3 {
      font-size: 20px;
    }

    .new-post-input-wrapper {
      margin-top: 15px;

      .btn {
        margin-left: 15px;
        width: 85px;
        padding: 0.4rem 0.75rem;
      }

      input {
        padding: 0 17px;
        font-size: 16px;
        line-height: 100%;
      }

      #submit-keyword {
        font-size: 14px;
      }
    }

    .form-card {
      padding: 20px;
      margin: 0 0 30px 0;
      width: 100%;
    }

    .buttons-wrapper {
      font-size: 18px;
    }

    .tip-component {
      float: right;
    }

    .questions-wrapper .question-item {
      margin: 10px 0;
      padding: 12px 17px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 8px;
      width: 100%;
    }

    .trends-value-wrapper {
      margin-right: -7px;

      .trend-component {
        height: 150px;
        padding-bottom: 20px;
        margin: 0 7px 0 0;

        .column-name {
          font-size: 10px;
          line-height: 14px;
        }

        .column-value div {
          width: 17px;
        }
      }
    }

    .keyword-ideas {
      .keyword-ideas__wrapper {
        flex-direction: column;
        justify-content: space-around;

        .keyword-ideas__item {
          width: 100%;
          padding: 12px 15px;
        }
      }
    }

    .action-buttons-container {
      bottom: 10px;
      right: 20px; // Increased from 10px
      left: 20px; // Added to ensure buttons don't stretch full width on very small screens
    }

    .action-button {
      padding: 12px 16px; // Slightly increased padding for better touch targets
      font-size: 16px; // Kept font size consistent
      width: 100%; // Make buttons full width on mobile
      max-width: 200px; // Limit maximum width
      margin: 0 auto; // Center buttons if they're not full width
    }
  }
}
