@import '../styles/constants';
@import '../styles/auth-wrapper';

.register-wrapper {
  h1 {
    margin-bottom: 25px !important;
  }

  form {
    width: 415px;
    max-width: 100%;
    margin: 0 auto;

    .btn {
      margin-top: 0 !important;
    }

    .form-check label {
      margin: 0;
      position: static;
      padding-left: 10px;
      font-size: 14px;
      color: #ff006e;
      line-height: 17px;
      letter-spacing: 0.4px;
    }
  }

  .privacy-policy {
    color: #b7bacf;
    font-size: 13px;
    letter-spacing: 0.52px;
    line-height: 15px;
    text-align: center;
    margin: 20px 0;
  }

  .avatar {
    background: url('../styles/images/jania-headshot.jpg') center;
    background-size: cover;
    aspect-ratio: 1/2;
  }

  .testimonial-section {
    height: auto;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1.5rem;

    .testimonial-carousel {
      flex: 1;
      display: flex;
      align-items: center;
      max-width: 600px;
      margin: 0 auto;
      padding: 1rem 0;

      .carousel-container {
        width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 0.75rem;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(10px);
        padding: 1.25rem;

        .profile-image-container {
          width: 64px;
          height: 64px;
          margin: 0 auto 0.75rem;
          position: relative;

          .profile-ring {
            position: absolute;
            inset: -2px;
            border-radius: 50%;
            background: linear-gradient(45deg, #e879f9, #c084fc);
            animation: spin 8s linear infinite;
          }

          .profile-image {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid #fff;
          }
        }

        .testimonial-content {
          text-align: center;
          color: #fff;
          margin-top: 0.5rem;
          padding: 0 1rem;

          .quote {
            font-size: 1rem;
            line-height: 1.5;
            font-style: italic;
            margin-bottom: 0.75rem;
          }

          .author {
            font-weight: 600;
            font-size: 1rem;
            margin-top: 1rem;
          }
        }

        .navigation {
          display: flex;
          justify-content: center;
          gap: 0.375rem;
          margin-top: 0.75rem;

          .nav-dot {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.4);
            transition: all 0.3s ease;
            cursor: pointer;
            padding: 0;
            border: none;

            &.active {
              background: #fff;
              transform: scale(1.2);
            }
          }
        }
      }
    }

    .metrics-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.75rem;
      max-width: 800px;
      margin: 1rem auto;

      .metric-item {
        text-align: center;
        padding: 0.5rem;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 0.5rem;

        .value {
          font-size: 1.5rem;
          font-weight: 700;
          color: #fff;
          margin-bottom: 0.25rem;
        }

        .label {
          color: rgba(255, 255, 255, 0.9);
          font-size: 0.75rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .tagline {
      text-align: center;
      color: white;
      font-size: 1.25rem;
      font-weight: 500;
      opacity: 0.9;
      margin-top: 0.75rem;
      padding: 0 1rem;
      line-height: 1.5;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
