@import '../styles/constants';

.form-group {
  margin-bottom: 1.2rem;

  .custom-input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #c6c5c8;
    background: #fff;
    padding: 33px 40px 16px 40px;
    box-sizing: border-box;
    display: block;
    color: #35384d;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 19px;

    @include mobile {
      padding: 27px 20px 12px 20px;
    }
  }

  .custom-input:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(#7f0799, 0.25);
  }

  label {
    color: #b7bacf;
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 21px;
    padding-left: 40px;
    position: absolute;
    margin-top: -46px;
    transition: 0.2s ease all;

    @include mobile {
      padding-left: 20px;
      margin-top: -41px;
    }
  }

  input:focus + label,
  input.has-value + label {
    font-size: 10px;
    letter-spacing: 0.4px;
    line-height: 11px;
    margin-top: -50px;

    @include mobile {
      margin-top: -48px;
    }
  }

  // TODO valid and invalid fields should have icons, same for other screens
  .invalid.has-value {
    border: 1px solid #fdc2a6;
  }

  .valid.has-value {
    border: 1px solid #d785ff;
  }
}
