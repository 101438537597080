.form-password-wrapper {
  label {
    display: block;
    color: #1a051d;
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    margin-bottom: 8px;
  }

  input {
    height: 48px;
    width: 100%;
    color: #3f3356;
    font-size: 15px;
    line-height: 20px;
    border: 1px solid #ecebed;
    border-radius: 6px;
    box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
    padding-left: 16px;
  }

  .is-valid {
    border: 1px solid #fdc2a6;
  }

  .inner-wrapper {
    display: flex;
  }

  .img-wrapper {
    height: 48px;
    margin-left: -34px;
    display: flex;
  }
}
