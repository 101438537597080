.editor {
  min-height: 200px;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: rgba(111, 45, 189, 0);
  padding: 0px 15px 10px 20px;
  font-family: Roboto, sans-serif;
  // font-size: 15px;
  font-weight: 500;
  // letter-spacing: 0.17px;
  // line-height: 20px;
  border: 0.5px solid #cbcacd;
  border-radius: 6px;
  margin-top: 12px;
}

.mce-edit-focus {
  outline: none;
}

.editor::before {
  padding-left: 19px;
  font-size: 15px;
  font-style: italic;
  letter-spacing: 0.17px;
  line-height: 20px;
}

pre {
  white-space: pre-line;
  white-space: pre-wrap;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.tox:not([dir='rtl']) {
  direction: ltr;
  text-align: left;
  outline: 0 !important;
  border: none;
}

/* Remove box shadow from header */
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: #fff !important;
  border-bottom: none !important;
  box-shadow: none !important;
  padding: 0px 0px !important;
}

.tox
  .tox-collection--list
  .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  background-color: #6f2dbd !important;
  color: #fff;
}

.tox .tox-tbtn--active,
.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:focus,
.tox .tox-tbtn--enabled:hover {
  background: #eadef7 !important;
}

.tox .tox-button {
  background-color: #6f2dbd !important;
  border-color: #6f2dbd !important;
}

.tox .tox-button--naked {
  background-color: transparent !important;
  border-color: transparent !important;
}

.tox .tox-button--secondary[disabled] {
  background-color: #f0f0f0 !important;
  border-color: #f0f0f0 !important;
}

.tox .tox-button--secondary {
  color: #fff !important;
}

// Remove border from editor
.tox .tox-edit-area::before {
  border: 0 !important;
}

// .tox-toolbar__group {
//   &:last-child {
//     .tox-tbtn:last-child {
//       background-color: #fb5607 !important;
//       color: #fff !important;
//     }
//   }

//   &:nth-child(3) {
//     .tox-tbtn:last-child {
//       background-color: #6f2dbd !important;
//       color: #fff !important;
//     }
//   }

//   &:nth-child(4) {
//     .tox-tbtn:last-child {
//       background-color: #fb5607 !important;
//       color: #fff !important;
//     }
//   }
// }

.editor-loading-wrapper {
  position: relative;

  .editor-loading {
    position: absolute;
    top: 1px;
    left: 5px;
    z-index: 999;
    background-color: #fff;
    width: 98%;
    height: 98%;

    .loading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .editor-loading-message {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #1a051d;
      margin-top: -15px;
    }
  }
}
