@import '../styles/constants';

.post-preview-component {
  margin: 0 25px 25px 25px;
  background-color: #ffffff;

  .preview-header-wrapper {
    border-radius: 0 0 10px 10px;
    background-color: #6f2dbd;
    padding: 0 40px;
    margin-bottom: 20px;
  }

  .header-wrapper {
    height: 45px;
    max-width: 1210px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: end;
    padding: 30px 40px 43px;
    box-sizing: content-box;
    will-change: padding;
    transition: padding 0.3s linear;

    .btn {
      width: 135px;
      line-height: 29px;
      transition: all 0.3s linear;
    }
  }

  .arrow {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4.5px;
    margin-bottom: 3px;
    margin-left: 8px;
  }

  .arrow-white {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4.5px;
    margin-bottom: 3px;
    margin-left: 8px;
  }

  .arrow-purple {
    border: solid #6f2dbd;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4.5px;
    margin-bottom: 3px;
    margin-left: 8px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .preview-post-wrapper {
    margin-top: 20px;
  }

  .post-content-wrapper {
    max-width: 1020px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 40px;
    margin: auto;
    box-sizing: content-box;
    // HM added to position hover div for steps
    position: relative;
  }

  .footer {
    height: 45px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }

  .btn {
    padding: 6px 12px;
  }

  // common blocks styles
  .preview-header {
    color: #ffffff;
    font-family: sans-serif;
    font-weight: 300;
    font-size: 44px;
    letter-spacing: 0.27px;
    line-height: 120px;
    text-align: center;
    margin: 0;
  }

  h1 {
    font-size: 3.5rem;
    line-height: 4.5rem;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: 600;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: 600;
    margin-top: 1em;
  }

  h3 {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;
    margin-top: 1em;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 1.75em;
    font-weight: 600;
    margin-top: 1em;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-top: 1em;
  }

  h6 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin-top: 1em;
  }

  p {
    font-size: 1.2rem;
    margin-top: 1.3em;
  }

  pre {
    font-size: 1.2rem;
  }

  ul {
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  ol {
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  .image-wrapper {
    width: 70%;
    display: block;
    margin: 40px auto 40px auto;
    border-radius: 6px;
  }

  .hero-image-wrapper {
    width: 90%;
    display: block;
    margin: 80px auto 80px auto;
    border-radius: 6px;
  }

  p a {
    color: #a855f7;
    text-decoration: none;
  }

  p a:hover {
    color: #7e22ce;
    text-decoration: underline;
  }

  p a[target='_blank'] {
    color: #a855f7;
    text-decoration: none;
  }

  p a[target='_blank']:hover {
    color: #7e22ce;
    text-decoration: underline;
  }

  // .done-dropdown .dropdown-menu button,
  // .done-dropdown .dropdown-menu a {
  //   padding: 8px 16px 8px 30px;
  // }

  .done-dropdown .dropdown-menu button {
    padding: 8px 16px 8px 30px;
  }

  .export-btn {
    position: relative;

    &::before {
      content: '';
      border: solid #3f3356;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      position: absolute;
      left: 13px;
      top: 13px;
    }

    &:hover::before {
      border-color: #6f2dbd;
    }

    div {
      display: none;
      position: absolute;
      left: -115px;
      top: 0;
      padding-right: 10px;
      width: 115px;

      ul {
        width: 100%;
        list-style: none;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 #979797;
        margin: 0;
        padding: 5px 0;

        a {
          padding: 5px 16px;
        }
      }
    }

    &:hover div {
      display: block;
    }
  }

  @include mobile {
    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    .hero-image-wrapper {
      width: 90%;
      margin: 40px auto 40px auto;
      border-radius: 6px;
    }
  }
}

.y-scrolling {
  .post-preview-component .header-wrapper {
    position: fixed;
    left: 25px;
    right: 25px;
    top: 0;
    padding: 15px 40px 13px 40px;
    box-shadow: 0 2px 4px 0 #979797;
    border-radius: 0 0 10px 10px;
    z-index: 1;

    .btn {
      width: 100px;
      padding: 5px 10px;
      height: 40px;
      line-height: 26px;
      border-radius: 7px !important;
    }
  }

  .preview-post-wrapper {
    margin-top: 145px;
  }
}
