@import '../../styles/constants';

.image-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  & > div {
    max-width: 1140px;
    // max-height: 90%;
    max-height: 85vh;
    padding: 10px;
    //text-align: center;
  }

  h1 {
    color: $black-color;
    font-size: 24px;
    line-height: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  input {
    height: 45px;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    border: 0.5px solid #cbcacd;
    border-radius: 6px;
    background-color: rgba(111, 45, 189, 0);
    margin: 0px 0px 10px 0px;
    color: #000000;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 34px;
    padding: 5px 10px 5px 10px;
  }

  input:focus {
    border: 1px solid #6f2dbd;
  }

  .header-wrapper {
    height: 15%;
    //background-color: salmon;
  }

  .content-wrapper {
    height: 65vh;
    overflow: scroll;
  }

  .buttons-wrapper {
    //background-color: pink;
    display: flex;
    margin: 10px 0px 0px 0px;
    justify-content: space-between;

    .btn {
      width: 145px;
    }
  }

  .warning {
    color: $orange-color;
    font-size: 16px;
  }

  // .image-wrapper {
  //   //margin: 0 20px;
  // }

  .checkmark-button-off {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    // background: #ff2183;
    background-repeat: no-repeat;
    background-image: url('../../styles/images/checkmark-round-off.svg');
    position: relative;
    left: -10px;
    top: 0px;
    display: block;
  }

  .checkmark-button-on {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    // background: #ff2183;
    background-repeat: no-repeat;
    background-image: url('../../styles/images/checkmark-round-on.svg');
    position: relative;
    left: -10px;
    top: 0px;
    display: block;
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 0 10px;
    grid-auto-rows: 10px;
  }

  .image-list img {
    width: 250px;
  }
}
