@import '../styles/constants';

.post-create-component {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    color: $black-color;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    padding: 0 20px;
    margin: 90px 0 50px 0;
  }
  label {
    font-weight: bold;
    font-size: 13px;
  }

  .keyword-wrapper {
    // margin-bottom: 22px;
    margin: 0px auto 10px auto;
    padding: 15px;
    width: 90%;
    max-width: 765px;

    .keyword-label {
      display: block;
      color: #1a051d;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 16px;
    }

    .keyword-element {
      height: 48px;
      border: 1px solid #dcdcdc;
      border-radius: 6px;
      background-color: #ecebed;
      box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
      color: #3f3356;
      font-size: 15px;
      line-height: 48px;
      padding: 0 16px;
    }
  }

  .extra-space {
    margin-top: 20px;
  }

  .tip-component {
    width: 14px;
    padding: 0 4px;
    line-height: 16px;
    font-size: 12px;
  }

  .request-link,
  p {
    text-align: center;
    letter-spacing: 0.52px;
    line-height: 15px;
    text-align: center;
    margin-top: 10px;
  }

  .items-list {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1312px;
    justify-content: center;
  }

  .item {
    height: 191px;
    width: 228px;

    box-sizing: border-box;
    border: 1px solid $main-contrast;
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(129, 129, 129, 0.46);

    opacity: 0.8;
    color: #000000;
    font-size: 34px;
    font-weight: 900;
    line-height: 41px;

    text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    margin: 50px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item:hover {
    background: $main-contrast;
    color: #fff;
  }

  .controls-wrapper {
    width: 90%;
    max-width: 765px;
    margin: 20px auto 0px auto;
    padding: 15px;
  }

  .clear-button {
    padding: 0;
  }

  .create-button {
    width: 100%;
    max-width: 765px;
    margin: 20px auto 0px auto;
  }

  .box {
    overflow: hidden;
    margin: -8px auto 0px auto;
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 765px;
    justify-content: center;

    .block {
      width: 100%;
      max-width: 765px;
      padding: 15px;

      &:last-child {
        margin: 25px 0 0 0;
      }

      .block-title {
        font-weight: bold;
        font-size: 13px;
      }

      .block-box {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 0 0;

        .keyword-ideas__wrapper {
          display: flex;
          align-items: start;
          flex-wrap: wrap;
        }

        .block-item {
          margin: 5px 10px 0 0;
          padding: 4px 8px;
          font-size: 13px;
          border: 1px solid #dedede;
          border-radius: 10px;
          color: #714fbd;
          cursor: pointer;

          &:hover {
            border: 1px solid #714fbd;
            background: #714fbd;
            color: #fff;
          }
        }

        .block-item-selected {
          margin: 5px 10px 0 0;
          padding: 4px 8px;
          font-size: 13px;
          border: 1px solid #714fbd;
          border-radius: 10px;
          background: #714fbd;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .inputs-wrapper {
      width: 100%;
      max-width: 765px;
      margin: 20px auto 0px auto;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;

      .container {
        width: 80%;
        .input-title {
          font-weight: bold;
          font-size: 13px;
        }

        .account-input {
          //
        }
      }

      .right-button {
        width: 20%;

        .btn {
          width: 90%;
          line-height: 25px;
          margin-top: 22%;
        }
      }
    }
  }

  @include mobile {
    h1 {
      margin: 80px 0 10px 0;
      font-size: 24px;
    }

    .create-button {
      margin: 5px auto 0px auto;
    }

    .box {
      .block {
        margin-top: 25px;
      }
      .inputs-wrapper {
        .container {
          width: 100%;
        }

        .right-button {
          width: 100%;
          height: 70px;
          padding: 15px;
          .btn {
            margin-top: 5px;
            width: 100%;
            line-height: 25px;
          }
        }
      }
    }

    .keyword-wrapper {
      margin: 0px auto 0px auto;
    }

    .box {
      margin: -20px auto 0px auto;
    }

    .inputs-wrapper {
      margin: 20px auto 0px auto;
    }
  }
}
