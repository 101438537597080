.trend-component {
  width: 40px;
  margin: 0 10px;
  height: 240px;
  padding-bottom: 40px;
  position: relative;

  .column-value {
    height: 100%;
    display: flex;

    div {
      border-radius: 3px;
      width: 40px;
      align-self: flex-end;

      &:hover {
        box-shadow: 0 6px 10px #616161;
      }
    }
  }

  .column-name {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    letter-spacing: 0.16px;
    line-height: 19px;
    text-align: center;
    color: #a39da9;
    width: 100%;
  }
}

.trend-tip-value {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}
