.ai-writing-tab {
  position: relative;
  min-height: 500px;
  width: 30rem;

  @media only screen and (max-width: 360px) {
    width: 17rem;
  }

  @media only screen and (max-width: 600px) {
    width: 22rem;
  }

  .list-item {
    width: 28rem !important;

    @media only screen and (max-width: 360px) {
      width: 17rem !important;
    }

    @media only screen and (max-width: 600px) {
      width: 21rem !important;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }

  .rainbow {
    position: relative;
    z-index: 0;
    border-radius: 3px;
    overflow: hidden;
    padding: 2rem;
    animation-duration: 5s;

    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-color: #399953;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient(#7c3aed, #d8b4fe),
        linear-gradient(#7c3aed, #d8b4fe), linear-gradient(#7c3aed, #d8b4fe),
        linear-gradient(#7c3aed, #d8b4fe);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 4px;
      top: 4px;
      width: calc(100% - 9px);
      height: calc(100% - 9px);
      background: white;
      border-radius: 3px;
    }
  }

  & .hidden {
    visibility: hidden;
    opacity: 0;
  }

  .tool-tab {
    position: relative;
    height: 73vh;

    .tab-content {
      height: 75vh;
      overflow-y: auto;
      overflow-x: hidden;
      scroll-behavior: smooth;
      padding-bottom: 40px;

      @media only screen and (max-height: 850px) {
        padding-bottom: 70px;
      }

      .tell-the-ai {
        position: relative;
        width: 376px;
        height: 212px;
        background: url('../styles/images/tell-the-ai-what-to-write.png') center;
        background-size: cover;
        margin: 20px auto;

        .btn-close {
          position: absolute;
          right: 0;
          top: -10px;
          cursor: pointer;
        }

        .btn-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.command-modal {
  position: relative;
  width: 70vw;
  height: 70vh;
  margin: 40px auto;
  max-width: 100%;
  max-height: 100%;
  border: none;

  @media only screen and (max-width: 524px) {
    width: 100vw;
    height: 50vh;
  }

  iframe {
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 95%;
    height: 85%;
    text-align: center;
    margin: auto;
  }

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.manage-command-modal {
  position: relative;
  width: 50vw;
  height: 80vh;
  margin: 40px auto;
  max-width: 100%;
  max-height: 100%;
  background: white;

  .modal-content {
    height: 60vh;
    overflow-y: auto;
  }

  @media only screen and (max-width: 524px) {
    width: 100vw;
    height: 50vh;
  }

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .btn-wrapper {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
}
