@import '../styles/constants';

.posts-list {
  .status-message {
    text-align: center;

    h2 {
      font-size: 24px;
      padding-top: 80px;
      margin-bottom: 45px;
      color: $black-color;
      line-height: 30px;
    }

    a {
      min-width: 200px;
    }
  }

  .support {
    margin: 20px auto 0 auto;
    text-align: center;
    font-size: 16px;
    max-width: 500px;
  }

  .nova-ai-blogging-assistant {
    position: relative;
    width: 500px;
    height: 300px;
    background: url('../styles/images/bramework-nova-ai-blogging-assistant.png')
      center;
    background-size: cover;
    margin: 20px auto;

    .btn-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .blank-page-to-blog-post {
    position: relative;
    width: 500px;
    height: 300px;
    background: url('../styles/images/bramework-blank-page-to-blog-post.png')
      center;
    background-size: cover;
    margin: 20px auto;

    .btn-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
