.nova-message {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  color: #111827;
  overflow: auto;
  z-index: 9999;
  font-size: 1rem;

  code {
    @apply block;
    @apply p-2;
    @apply bg-gray-700;
    @apply rounded-lg;
    @apply my-2;
    @apply font-bold;
    @apply text-gray-200;
    width: fit-content;
    font-family: 'Courier New', Courier, monospace;
  }

  ul,
  ol,
  dl {
    @apply px-5;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: auto;
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
