.tip-component {
  display: inline-block;
  width: 20px;
  border-radius: 3px;
  background-color: #cbcbcb;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 0 6px;
  align-self: flex-start;
  vertical-align: top;
  cursor: pointer;
}
