// Center toolbar on page
.tox .tox-toolbar__primary {
  justify-content: center !important;
}

.tox .tox-edit-area {
  margin-top: 180px;
}

.tox .tox-editor-header {
  position: fixed;
  margin: auto;
  width: 90%;
  z-index: 11 !important;
}

.titlearea {
  // font-size: 2em;
  // font-weight: 800;
  font-family: Roboto, sans-serif;
  overflow: hidden;
  display: block;
  min-height: 48px;
  line-height: 32px;
  margin: 7% 15% 0% 15%;
  padding-top: 10px;
  width: 80%;
  font-size: 2rem;
}

.title-area-space-md {
  margin-top: 9%;
}

.title-area-space-lg {
  margin-top: 10%;
}

.titlearea[contenteditable]:empty::before {
  content: 'Enter title here...';
  color: #d1d5db;
}

// Additional toolbar bottom border .tox .tox-toolbar,
// .tox .tox-toolbar__overflow,
// .tox .tox-toolbar__primary {
//   border-bottom: 1px solid #dadce0;
// }

// Removes border between toolbar groups
.tox .tox-menubar + .tox-toolbar,
.tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary,
.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
  border: 0 !important;
  padding: 0 7px 0 7px;
}

// Adds padding between toolbar groups
// .tox .tox-toolbar__group {
//   align-items: center;
//   display: flex;
//   flex-wrap: wrap;
//   margin: 0 0;
//   padding: 0 8px 0 8px;
// }

// Remove shadow when toolbar scrolls
.toolbar,
.tox .tox-menubar,
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  box-shadow: none;
}

.fact-checker-panel {
  .list-item {
    width: 28rem !important;

    @media only screen and (max-width: 360px) {
      width: 17rem !important;
    }

    @media only screen and (max-width: 600px) {
      width: 21rem !important;
    }
  }
}

.post-builder-navigation {
  height: calc(100vh - 64px) !important;
  div[data-tip] {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}

.btn-generate {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 13px;
  align-items: center;
  transition: visibility 0s, opacity 0.5s linear;
  display: flex;
  opacity: 0;
  visibility: hidden;

  .side-bar-open & {
    opacity: 1;
    visibility: visible;
  }

  &:before {
    content: '';
    width: 100%;
    background: #6c757d;
    position: absolute;
    height: 70px;
    z-index: -1;
    background: rgba(245, 248, 250, 0.57);
    backdrop-filter: blur(5.5px);
  }

  button:disabled,
  button[disabled] {
    background: #e2e8f0;
    color: #9ba6b5;
  }
}
