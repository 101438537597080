.form-select-wrapper {
  label {
    display: block;
    color: #1a051d;
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    margin-bottom: 8px;
  }
}
