@import '../styles/constants';

.profile-wrapper {
  background: #fbfbfb;

  .profile-input,
  .profile-select {
    margin-bottom: 22px;
  }

  .form-inner {
    display: flex;
  }

  .avatar-wrapper {
    width: 80px;
    margin-right: 32px;
    text-align: center;
  }

  .avatar-wrapper img.avatar {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }

  .avatar-wrapper .control-wrapper {
    display: flex;
    justify-content: center;
  }

  .avatar-wrapper .control {
    width: 20px;
    height: 20px;
  }

  .avatar-wrapper .avatar-label {
    opacity: 0.7;
    color: #3f3356;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    margin-top: 4px;
  }

  .inputs-wrapper {
    max-width: 500px;
  }

  .country-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .country-select {
    margin-right: 20px;
  }

  .country-wrapper .form-select-wrapper {
    width: 240px;
  }

  .email-change-wrapper {
    margin-bottom: 22px;

    .email-change-label {
      display: block;
      color: #1a051d;
      font-size: 14px;
      font-weight: 500;
      line-height: 13px;
      margin-bottom: 8px;
    }

    .email-change-element {
      height: 48px;
      border: 1px solid #dcdcdc;
      border-radius: 6px;
      background-color: #ecebed;
      box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
      color: #3f3356;
      font-size: 15px;
      line-height: 48px;
      padding: 0 16px;
    }
  }

  @include mobile {
    .form-inner {
      display: block;
    }

    .avatar-wrapper {
      width: auto;
    }

    .country-wrapper .form-select-wrapper {
      width: 100%;
      margin-right: 0;
    }
  }
}
