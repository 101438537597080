@import '../styles/constants';
// @import '~bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: 'Publica Play Regular';
  src: url('fonts/FaceType-PublicaPlay-Regular.woff');
  font-style: normal;
  font-weight: normal;
}

// html,
// body {
//   background-color: $lightest-bg;
// }

// a {
//   color: $color-pink;
// }

// a:hover {
//   color: $main-contrast;
//   text-decoration: none;
// }

// a:focus,
// button:focus {
//   outline: none;
// }

.btn {
  font-size: 18px;
  color: #ffffff !important;
  border: 2px solid !important;
  border-radius: 10px !important;
  letter-spacing: 0.02rem;
  padding: 0.6rem 0.75rem;

  &:disabled {
    background: #b7bacf !important;
    border-color: #b7bacf !important;
    color: #ffffff !important;
    cursor: default;

    &:hover {
      box-shadow: 0 0 0 0.2rem rgba(183, 186, 207, 0.25) !important;
      color: $gray-color !important;
    }
  }

  &.active,
  &:hover,
  &:focus {
    background-color: #ffffff !important;
  }

  &.btn-sm {
    font-size: 10px;
    line-height: 35px;
    text-transform: uppercase;
    padding: 1px 25px 0 25px;
    border-width: 1px;
  }
}

.btn.btn-lg {
  height: 70px;
}

.btn-purple {
  background: $main-contrast !important;
  border-color: $main-contrast !important;

  &.active,
  &:hover,
  &:focus {
    color: $main-contrast !important;
    box-shadow: 0 0 0 0.2rem rgba($main-contrast, 0.25) !important;
  }

  &.btn-link {
    background: #ffffff;
    color: $main-contrast;
  }
}

.btn-red {
  background: $red-color;
  border-color: $red-color;

  &.active,
  &:hover,
  &:focus {
    color: $red-color;
    box-shadow: 0 0 0 0.2rem rgba(255, 27, 110, 0.25);

    i {
      border: solid $red-color;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4.5px;
      margin-bottom: 3px;
      margin-left: 6px;
    }
  }
}

.btn-green {
  background-color: $green-color !important;
  border-color: $green-color !important;

  &.active,
  &:hover,
  &:focus {
    color: $green-color !important;
    box-shadow: 0 0 0 0.2rem rgba(35, 207, 51, 0.25) !important;
  }
}

.btn-blue {
  background: $blue-color;
  border-color: $blue-color;

  &.active,
  &:hover,
  &:focus {
    color: $blue-color;
    box-shadow: 0 0 0 0.2rem rgba(36, 206, 207, 0.25);
  }
}

.btn-yellow {
  background: $yellow-color;
  border-color: $yellow-color;

  &.active,
  &:hover,
  &:focus {
    color: $yellow-color;
    box-shadow: 0 0 0 0.2rem rgba(255, 190, 11, 0.25);
  }
}

.btn-orange {
  background: $orange-color;
  border-color: $orange-color;

  &.active,
  &:hover,
  &:focus {
    color: $orange-color;
    box-shadow: 0 0 0 0.2rem rgba(251, 85, 7, 0.25);
  }
}

.btn-white {
  background: #ffffff;
  border-color: $lightgray-color;
  color: $black-color;
  border-width: 1px;
  line-height: 1.65;

  &.active,
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 220, 220, 0.25);
  }
}

.btn-outline-purple {
  background: #ffffff;
  border-color: $main-contrast;
  color: $main-contrast;
  border-width: 1px;
  line-height: 1.65;

  &.active,
  &:hover,
  &:focus {
    color: $main-contrast;
    box-shadow: 0 0 0 0.2rem rgba($main-contrast, 0.25);
  }
}

.btn-outline-green {
  background: #ffffff;
  border-color: $green-color;
  color: $green-color;
  border-width: 1px;
  line-height: 1.65;

  &.active,
  &:hover,
  &:focus {
    color: $green-color;
    box-shadow: 0 0 0 0.2rem rgba($green-color, 0.25);
  }
}

.message {
  padding: 20px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  border-radius: 6px;
  background-color: $main-contrast;
  display: flex;

  @include mobile {
    margin: 0 10px 10px 10px !important;
  }
}

#__react-alert__ > div {
  margin-top: 30px;

  @include mobile {
    margin-top: 10px;
  }
}

.message button {
  padding: 0;
  margin: 0;
  display: flex;
}

.message button img {
  width: 16px;
  margin-left: 20px;
}

.message.success {
  background: #24cf33;
}

.message.error {
  background: #fb5607;
}

.message.info {
  background: #24cecf;
}

.reactivate-button {
  color: #fff;
  text-decoration: underline;
}

// .cards-wrapper {
//   padding: 50px 70px;

//   @include mobile {
//     padding: 20px;
//   }
// }

.form-card {
  background: #fff;
  box-shadow: 0 2px 4px 0 #979797;
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 34px 40px;

  .button-wrapper {
    font-size: 16px;
    padding-top: 0.75rem;
  }

  h3,
  h4 {
    color: $black-color;
    font-weight: bold;
    letter-spacing: 0.27px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
  }

  h4 {
    font-size: 18px;
    line-height: 18px;
  }

  .info-text {
    font-size: 12px;
    line-height: 17px;
    color: $gray-color;
  }

  @include mobile {
    padding: 17px;
    margin-bottom: 20px;

    .button-wrapper {
      padding: 0;
    }
  }
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;

  .btn {
    min-width: 135px;
  }
}

.logo-icon {
  width: 110px;
  // width: 148.28px;
  height: 70px;
  background: url(images/logo-icon-purple.svg) center center no-repeat;
  background-size: contain;
}

.logo-icon.white {
  background-image: url(images/logo-icon-white.svg);
}

.clear-button {
  background: none;
  border: none;
}

.plain-button {
  box-sizing: border-box;
  height: 49px;
  width: 134px;
  border: 1px solid $main-contrast;
  border-radius: 10px;
  background-color: rgba(255, 0, 110, 0);

  color: $main-contrast;
  font-size: 20px;
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.plain-button:hover {
  text-decoration: none;
  color: $main-contrast;
}

.flat-button {
  color: $main-contrast;
  font-size: 22px;
  text-align: center;
}

.tooltip-for-templates.type-light.show {
  text-align: center;
  border-radius: 8px;
  border: none;
  background: $tooltip-bg;
  opacity: 1;
  width: 200px;
  color: #fbfbfb;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  padding: 10px;
}

.tooltip-for-templates.type-light.show.place-top:before,
.tooltip-for-templates.type-light.show.place-top:after {
  border-top: 8px solid $tooltip-bg;
}

.tooltip-for-templates.type-light.show.place-left:before,
.tooltip-for-templates.type-light.show.place-left:after {
  border-left: 8px solid $tooltip-bg;
}

.tooltip-for-templates.type-light.show.place-right:before,
.tooltip-for-templates.type-light.show.place-right:after {
  border-right: 8px solid $tooltip-bg;
}

.tooltip-for-templates.type-light.show.place-bottom:before,
.tooltip-for-templates.type-light.show.place-bottom:after {
  border-right: 8px solid $tooltip-bg;
}

@include mobile {
  .hidden-xs {
    display: none !important;
  }

  .form-group .custom-input {
    padding: 34px 20px 17px 20px;
  }
}

.ReactModal__Overlay {
  z-index: 201;
  background-color: rgba(107, 114, 128, 0.75) !important;
}

.menu-wrapper {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 #979797;
  padding: 8px 0;
  margin-top: 10px;

  button,
  a {
    display: block;
    width: 100%;
    text-align: left;
    color: #3f3356;
    font-size: 15px;
    line-height: 20px;
    padding: 8px 16px;
    border: 0 none;
    background: transparent;

    &:hover {
      color: $main-contrast;
      text-decoration: none;
    }
  }
}

.back-button {
  position: absolute;
  left: 25px;
  top: 25px;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  /* Primary/50 */

  background: #f9f5ff;
  /* Primary/600 */

  border: 1.375px solid #7f56d9;
  border-radius: 5.5px;

  &.Mui-checked {
    color: #883ae1 !important;
  }
}

.post-builder-tab {
  .list-item {
    width: 32rem;

    @media only screen and (max-width: 360px) {
      width: 22rem;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
    }
  }
}

.MuiLinearProgress-colorPrimary {
  background-color: #d1d5db !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #7e22ce !important;
}

.MuiDrawer-paperAnchorBottom {
  border-radius: 30px 30px 0 0;
}
