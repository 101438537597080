@import '../../styles/constants';

.result-item-component {
  padding: 25px;
  margin: 19px;
  width: 300px;

  .item-title-row {
    .item-title {
      float: left;
      font-size: 19px;
      letter-spacing: 0.23px;
      line-height: 38px;
    }

    .tip-component {
      margin-left: 10px;
    }

    .item-complexity {
      opacity: 0.7;
      float: right;
      border-radius: 5px;
      padding: 9px 15px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.17px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .tip-component {
      float: right;
    }
  }

  .item-value {
    font-size: 36px;
    font-weight: 900;
    letter-spacing: 0.41px;
    line-height: 48px;
    text-align: center;
    color: #000000;
    margin-top: 25px;

    span {
      font-weight: 400;
      font-size: 27px;
      color: $gray-color;
    }
  }

  .green {
    background: $green-color;
  }

  .orange {
    background: $orange-color;
  }

  .red {
    background: $red-color;
  }
}
