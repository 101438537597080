@import '../styles/constants';

.invoices-wrapper {
  h4 {
    margin-bottom: 17px;
  }

  .info-text {
    margin-bottom: 40px;
  }

  .table {
    margin-bottom: 40px;

    thead th {
      font-size: 13px;
      color: $black-color;
      font-weight: 600;
      letter-spacing: 0.03em;
      border-top: 0;
      border-bottom: 1px solid #e9e9e9;
      padding: 0.75rem 1.4rem 0.75rem 0;

      &.download {
        width: 100px;
      }
    }

    tbody td {
      padding: 1.5rem 1.4rem 0 0;
      font-size: 13px;
      border-top: 0;

      &:nth-child(odd) {
        display: none;
      }

      &.amount {
        color: $gray-color;
      }

      .pdf-btn {
        display: inline-block;
        padding-left: 20px;
        position: relative;
        color: $black-color;
        text-decoration: underline;
        background: url(../styles/images/pdf.png) 0 center no-repeat;
        background-size: 16px;
        cursor: pointer;
      }
    }
  }

  @include mobile {
    .table {
      margin-bottom: 20px;

      thead {
        display: none;
      }

      tbody {
        tr {
          padding: 10px 0;
          display: inline-block;
          border-bottom: 1px solid #e9e9e9;
        }

        td {
          display: inline-block;
          width: 60%;
          padding: 0 0 0.1rem 0;
          font-size: 13px;

          &:nth-child(odd) {
            display: inline-block !important;
            width: 40%;
            padding-right: 10px;
          }
        }
      }
    }

    .info-text {
      margin-bottom: 10px;
    }
  }
}
