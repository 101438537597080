@import '../../styles/constants';

.post-item-component {
  height: 80px;
  width: 464px;
  border: 0.5px solid #979797;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  margin: 14px 0;
  position: relative;

  .order {
    width: 30px;
  }

  .main {
    overflow: hidden;
    width: 100%;
  }

  .post-title {
    height: 19px;
  }

  .post-title a,
  .order {
    font-size: 16px;
    letter-spacing: 0.18px;
    line-height: 19px;
    color: $main-contrast;
    vertical-align: top;
  }

  .main div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .post-link {
    opacity: 0.5;
    color: #000000;
    font-size: 13px;
    letter-spacing: 0.15px;
    line-height: 18px;
  }

  .emt-wrapper {
    .emt-title {
      color: #cbcbcb;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.14px;
      line-height: 19px;
      text-align: center;
      width: 46px;

      .tip-component {
        z-index: 3;
      }
    }

    .emt-value {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.16px;
      line-height: 18px;
      text-align: center;
    }

    .tip-component {
      font-size: 11px;
      width: 16px;
      padding: 0;
      text-align: center;
      line-height: 15px;
      font-weight: bold;
      padding-top: 1px;
      margin-left: 0;
      position: absolute;
      right: 12px;
      top: 20px;
    }
  }
}
