@import '../../styles/constants';

.quality-confirmation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  img {
    display: block;
    margin: 20px auto 40px;
  }

  & > div {
    max-width: 350px;
    padding: 10px;
    text-align: center;
  }

  h1 {
    color: $black-color;
    font-size: 26px;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 145px;
    }
  }

  .warning {
    color: $orange-color;
    font-size: 16px;
  }
}
