@import '../styles/constants';
@import '../styles/auth-wrapper';

.login-wrapper {
  form {
    width: 415px;
    margin: 0 auto;
    max-width: 100%;
  }

  .forgot-password {
    font-size: 15px;
    letter-spacing: 0.6px;
    line-height: 18px;
    display: inline-block;

    @include mobile {
      top: -10px;
      position: relative;
    }
  }
}
