@import '../styles/constants';

.post-template-component {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    color: $main-contrast;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    padding: 0 20px;
    margin: 90px 0 50px 0;
  }

  .inputs-wrapper {
    width: 320px;
    margin: 0px auto -15px auto;
  }

  .extra-space {
    margin-top: 20px;
  }

  .tip-component {
    width: 14px;
    padding: 0 4px;
    line-height: 16px;
    font-size: 12px;
    margin: -8px 0 0 218px;
    position: absolute;
  }

  .request-link,
  p {
    text-align: center;
    letter-spacing: 0.52px;
    line-height: 15px;
    text-align: center;
    margin-top: 10px;
  }

  .items-list {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1312px;
    justify-content: center;
  }

  .item {
    height: 191px;
    width: 228px;

    box-sizing: border-box;
    border: 1px solid $main-contrast;
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(129, 129, 129, 0.46);

    opacity: 0.8;
    color: #000000;
    font-size: 34px;
    font-weight: 900;
    line-height: 41px;

    text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    margin: 50px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item:hover {
    background: $main-contrast;
    color: #fff;
  }

  .controls-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 20px;

    justify-self: flex-end;
  }

  .controls-wrapper .skip-button {
    margin-left: 20px;
  }

  .clear-button {
    padding: 0;
  }

  @include mobile {
    .inputs-wrapper {
      margin: auto;
    }

    h1 {
      margin: 80px 0 10px 0;
      font-size: 36px;
    }

    .inputs-wrapper {
      margin: 20px auto;
    }

    .items-list {
      margin: 25px auto;

      .item {
        height: 135px;
        width: 175px;
        margin: 15px;
      }
    }
  }
}
