@import '../../styles/constants';

.plans-wrapper {
  .no-account-access {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f5f8fa;
    overflow: auto;
    outline: none;
    padding: 20px;
  }

  .has-account-access {
    min-height: 100%;
    background: #fbfbfb;
  }

  h1 {
    color: #3c4149;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    margin: 15px 0;
    text-align: center;
    letter-spacing: -0.009em;
    line-height: 110%;
  }

  .plans-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .plan {
      max-width: 320px;
      margin: 15px;
      background: #fff;
      border-radius: 12px;
      padding: 20px 20px 70px 20px;
      position: relative;
      border: 0.8px solid #e2e8f0;
      align-self: flex-start;

      .trial-badge {
        background-color: $green-color;
        color: white;
        position: absolute;
        right: 0;
        top: 10px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 13px;
        padding: 2px 10px;
        border-radius: 3px 0 0 3px;
      }

      .plan-badge {
        color: #7429c6;
        padding: 4px 16px;
        width: fit-content;
        background: #f8f2ff;
        border-radius: 78px;
        margin: auto;
        font-weight: 600;
        font-size: 16px;
      }

      .btn-wrapper {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;

        .btn.active {
          box-shadow: none;
          cursor: default;
        }
      }

      .plan-description {
        font-size: 14px;
        font-style: normal;
      }

      h3 {
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        color: $black-color;
        text-align: center;
        margin: 20px 0 0 0;
      }

      br {
        display: block;
        height: 2px;
        position: relative;
        content: ' ';
      }

      p {
        font-size: 14px;
        margin: 10px 0;
        line-height: 18px;
      }

      .price-wrapper {
        text-align: center;
        padding-top: 20px;
        color: $black-color;
        padding-bottom: 16px;
        border-bottom: 1px solid #e2e8f0;

        i {
          font-size: 20px;
          font-style: normal;
          color: #b5b4b6;
          padding-bottom: 30px;
          vertical-align: top;
          padding-right: 5px;
        }

        .price {
          font-size: 32px;
          color: $black-color;
          font-style: normal;
          font-weight: 800;
          line-height: 125%;
          vertical-align: bottom;
          font-family: sans-serif;
        }

        span {
          font-weight: 600;
          vertical-align: bottom;
        }
      }
    }
  }

  .rotate-180 {
    rotate: 180deg;
  }

  .all-features {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;

    &.show {
      max-height: 400px;
      transition: max-height 0.4s ease;
    }
  }

  .all-appsumo-features {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;

    &.show {
      max-height: 460px;
      transition: max-height 0.4s ease;
    }
  }

  .add-ons-section {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #e5e7eb;
  }

  .feature-card {
    margin-top: 2rem;
    padding: 1.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    background-color: #ffffff;
  }

  .feature-list {
    margin-top: 1rem;
  }

  .feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .feature-item svg {
    margin-right: 0.5rem;
    color: #10b981;
  }
}
