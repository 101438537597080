.list-posts {
  table {
    width: 100%;
    margin: 40px 0;

    tr {
      height: 60px;
      border-bottom: 1px solid #eee;

      th {
        width: 20%;

        &:first-child {
          width: 60%;
        }
      }
    }

    .added-date {
      @media (max-width: 992px) {
        display: none;
      }
    }

    tbody {
      tr {
        height: 80px;

        th {
          color: #352238;
          font-weight: normal;
          width: 20%;

          &:first-child {
            width: 60%;
          }
        }
      }
    }

    .wrapper_btn {
      display: flex;

      .btn_item {
        margin: 0 15px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
