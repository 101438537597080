@import '../styles/constants';

.onboarding-component {
  min-height: 100%;
  display: flex;
  padding: 50px 20px 20px 20px;

  .inner {
    width: 100%;
    max-width: 1200px;
    margin: auto;
  }

  .logout {
    color: $main-contrast;
    font-size: 18px;
    padding: 5px 0;
    position: absolute;
    right: 40px;
    top: 20px;
    background-color: transparent;
    border: 0 none;
    width: 62px;

    &::after {
      content: '';
      border-bottom: 1px solid #6f2dbd;
      width: 100%;
      left: 0;
      position: absolute;
      right: 0;
      bottom: 5px;
    }
  }

  h1 {
    color: $black-color;
    font-size: 40px;
    line-height: 72px;
    text-align: center;
    margin-bottom: 6px;
  }

  h2 {
    color: $black-color;
    font-size: 24px;
    line-height: 72px;
    text-align: center;
  }

  .inputs-wrapper {
    width: 320px;
    margin: auto;
  }

  .form-input-wrapper,
  .form-select-wrapper {
    margin-top: 40px;
  }

  .button-wrapper {
    padding: 40px 0;
  }

  @include mobile {
    .logout {
      top: 10px;
    }

    h1 {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 12px;
    }

    h2 {
      font-size: 18px;
      line-height: 24px;
    }

    .inputs-wrapper,
    .btn {
      width: 100%;
    }
  }
}
